import moment from "moment";

/**
 * Message about daily hours or the hours for next open day (if closed)
 *
 * @param   {Object} storeState - Redux store state
 * @returns {string} Store hours message
 */
const getStoreHoursMessage = (storeState) => {
  const {
    closedAllDay,
    isFetchingHours,
    nextDayHours,
    todayHours,
  } = storeState.hours;
  if (isFetchingHours) return null;
  else if (!todayHours) return "No store hours for today";

  let { endTime: endTimestamp, startTime: startTimestamp } = todayHours;
  if (!endTimestamp || !startTimestamp) return "No store hours for today";

  let startTime = moment(startTimestamp).format("h:mma");
  let endTime = moment(endTimestamp).format("h:mma");

  if (closedAllDay || moment().isSameOrAfter(endTimestamp)) {
    // NOTE: Next day hours may be empty IF store is closed every day!
    if (!nextDayHours) return "Store is closed all week";

    startTime = moment(nextDayHours.startTime).format("h:mma");
    endTime = moment(nextDayHours.endTime).format("h:mma");

    // Indicate the next available day the store is open IF not tomorrow
    const nextStartTimestamp = moment(nextDayHours.startTime);
    const tomorrow = moment().add(1, "day").endOf("day");
    const tomorrowMessage =
      nextStartTimestamp < tomorrow
        ? "tomorrow"
        : moment(nextDayHours.startTime).format("dddd");

    return `Next open for deliveries ${tomorrowMessage} from ${startTime} to ${endTime}`;
  }

  return `Open for deliveries today from ${startTime} to ${endTime}!`;
};

export { getStoreHoursMessage };
