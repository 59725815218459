import React from "react";
import { Redirect } from "react-router-dom";
import { isAuthenticated } from "../../services/auth";

// eslint-disable-next-line no-unused-vars
export default (Component, requiredPermissions = []) => {
  // eslint-disable-next-line react/display-name
  return (props) => {
    const hasPermission = isAuthenticated();
    if (!hasPermission) {
      // eslint-disable-next-line react/prop-types
      return (
        // eslint-disable-next-line react/prop-types
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      );
    }
    return <Component {...props} />;
  };
};
