import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import { Button } from "../../components/inputs";
import { CartItem } from "./CartItem";
import { ChargesSection } from "./ChargesSection";
import { PaymentSection } from "./PaymentSection";

import { useStyles, WarningBoxStyles } from "./styled";
import { actionEmptyCartItems } from "./actions";
import {
  actionSetProductCharges,
  actionSetPaymentMethod,
} from "./../Checkout/actions";
import { createCharges } from "./helpers";

function Cart({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [cartError, setCartError] = useState("");

  const selectedCartItems = Object.values(
    useSelector((state) => state.cart.selectedCartItems)
  );
  const selectedCartItemsLength = selectedCartItems.length;
  const isOpen = useSelector((state) => state.hours.isOpen);
  const coupon = useSelector((state) => state.checkout.coupon);
  const selectedPaymentMethod = useSelector(
    (state) => state.checkout.paymentMethod
  );

  const emptyCart = () => dispatch(actionEmptyCartItems());
  const onRadioClick = ({ target }) =>
    dispatch(actionSetPaymentMethod(target.value));

  const submitCart = () => {
    if (!selectedPaymentMethod) {
      setCartError("Please select a payment method");
      return;
    }

    const charges = createCharges(
      selectedCartItems,
      selectedPaymentMethod,
      coupon
    );

    dispatch(actionSetProductCharges(charges, selectedPaymentMethod));

    history.push("checkout");
  };

  return (
    <Card className={classes.root} variant={"outlined"}>
      <CardHeader title="Cart" />

      <Divider />

      <CardContent className={classes.cartItemsContentRoot}>
        <Grid container>
          <Grid item>
            {!selectedCartItemsLength && (
              <WarningBoxStyles>Your cart is currently empty.</WarningBoxStyles>
            )}

            {cartError && <WarningBoxStyles> {cartError} </WarningBoxStyles>}

            {selectedCartItems.map(({ product, quantity, notes }) => (
              <CartItem
                key={product.id}
                product={product}
                quantity={quantity}
                notes={notes}
              />
            ))}

            {!!selectedCartItemsLength && (
              <Grid container>
                <Grid item lg={6}>
                  <PaymentSection onClick={onRadioClick} />
                </Grid>
                <Grid item lg={6}>
                  <ChargesSection
                    selectedPaymentMethod={selectedPaymentMethod}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>

      <Divider />

      <CardContent>
        {!!selectedCartItemsLength && (
          <Grid container justify="flex-end">
            <Grid item>
              <Button
                color="default"
                onClick={emptyCart}
                className={classes.cartEmptyButton}
              >
                Empty Cart
              </Button>
              {isOpen ? (
                <Button onClick={submitCart}>Checkout</Button>
              ) : (
                <Typography> The store is currently closed </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default Cart;
