import {
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Paper,
  Typography,
  Snackbar,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import isEmpty from "lodash.isempty";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";

// Components
import { Button } from "../../components/inputs";

import {
  actionCreateOrder,
  actionSaveAddress,
  actionUpdateAddress,
  actionLoadAddresses,
  SAVE_ADDRESS_SUCCESSFUL_TYPE,
  UPDATE_ADDRESS_SUCCESSFUL_TYPE,
  CREATE_ORDER_FAILED_TYPE,
  CREATE_ORDER_SUCCESSFUL_TYPE,
} from "./actions";
import { actionEmptyCartItems } from "./../Cart/actions";
import { useStyles } from "./style";

const CheckoutSchema = Yup.object().shape({
  address1: Yup.string().required("Required"),
  address2: Yup.string(),
  majorIntersection: Yup.string(),
  city: Yup.string().required("Required"),
  postalCode: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  phoneExt: Yup.string(),
  buzzerCode: Yup.string(),
  saveLocation: Yup.boolean(),
  varietyItems: Yup.string(),
  remainingPostalCode: Yup.string().when("postalCode", {
    is: (postalCode) => postalCode && postalCode.length === 3,
    then: Yup.string().required("Required"),
    otherwise: Yup.string(),
  }),
});

function Checkout({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [snackbarOpen, setSnackbarOpen] = useState({
    show: false,
    message: "",
  });
  const [selectedLocationID, setSelectedLocationID] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const paymentMethods = useSelector((state) => state.payments.payments);
  const selectedCartItems = useSelector(
    (state) => state.cart.selectedCartItems
  );
  const selectedPaymentMethod = useSelector(
    (state) => state.checkout.paymentMethod
  );
  const addresses = useSelector((state) => state.checkout.addresses);
  const charges = useSelector((state) => state.checkout.charges);
  const postalCodes = useSelector((state) => state.postalCodes.data);
  const coupon = useSelector((state) => state.checkout.coupon);
  const isReorder = useSelector((state) => state.cart.isReorder);

  useEffect(() => {
    if (!addresses.length) {
      dispatch(actionLoadAddresses());
    }
  }, [addresses.length]);

  if (
    isEmpty(selectedCartItems) ||
    isEmpty(selectedPaymentMethod) ||
    isEmpty(charges)
  ) {
    setTimeout(() => {
      history.push("/");
    }, 1500);
  }

  /**
   *
   */
  async function onSubmit(fieldValues) {
    const {
      saveLocation,
      updateLocation,
      varietyItems,
      remainingPostalCode,
      ...restOfFieldValues
    } = fieldValues;

    if (saveLocation || updateLocation) {
      let { postalCode } = restOfFieldValues;

      if (remainingPostalCode) {
        postalCode = postalCode + remainingPostalCode;
      }

      const { payload, type } = await dispatch(
        updateLocation
          ? actionUpdateAddress({
              ...restOfFieldValues,
              id: selectedLocation.id,
              province: "ON",
              postalCode,
            })
          : actionSaveAddress({
              ...restOfFieldValues,
              province: "ON",
              postalCode,
            })
      );

      if (
        type === SAVE_ADDRESS_SUCCESSFUL_TYPE ||
        type === UPDATE_ADDRESS_SUCCESSFUL_TYPE
      ) {
        const { data } = payload;
        const { id: addressId } = data[0];

        await handleCreateOrder(addressId, varietyItems);
      }
    } else {
      await handleCreateOrder(selectedLocation.id, varietyItems);
    }
  }

  /**
   *
   */
  async function handleCreateOrder(addressId, varietyItems) {
    const paymentMethod = paymentMethods.find(
      (method) => method.name === selectedPaymentMethod
    ).id;

    const products = Object.values(selectedCartItems).map((item) => ({
      id: item.product.id,
      quantity: item.quantity,
      notes: item.notes,
    }));

    return await dispatch(
      actionCreateOrder({
        address: addressId,
        paymentMethod,
        products,
        varietyItems,
        coupon,
        isReorder,
      })
    ).then((result) => {
      const { type, error } = result;

      if (type === CREATE_ORDER_SUCCESSFUL_TYPE) {
        dispatch(actionEmptyCartItems());
        showSnackbar("Order created", false);
      } else if (type === CREATE_ORDER_FAILED_TYPE) {
        const { data } = error.response;

        if (data.message) {
          showSnackbar(data.message, true);
        } else {
          showSnackbar("Something went wrong", true);
        }
      }
    });
  }

  /**
   *
   */
  function showSnackbar(message, error) {
    // TODO: Handle error case
    setSnackbarOpen({
      open: true,
      message,
    });
  }

  return (
    <React.Fragment>
      <Card className={classes.root} variant={"outlined"}>
        <CardHeader title="Checkout" />

        <Divider />

        <CardContent>
          <Grid container>
            <Grid item lg={12}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="selectedLocation">Saved Locations</InputLabel>
                <Select
                  fullWidth
                  disabled={isEmpty(addresses)}
                  labelId="selectedLocation"
                  name="selectedLocation"
                  label="Saved Locations"
                  onChange={({ target }) => {
                    setSelectedLocationID(target.value);
                    setSelectedLocation(
                      addresses.find((address) => address.id === target.value)
                    );
                  }}
                  value={selectedLocationID}
                >
                  {addresses.map(
                    ({ id, address1, address2, city, postalCode }) => {
                      let addressString = address1;

                      addressString += address2 ? `, ${address2}` : "";
                      addressString += `, ${city}, ${postalCode}`;

                      return (
                        <MenuItem value={id}>
                          <em>{addressString}</em>
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>

        <Divider />

        <Formik
          enableReinitialize
          initialTouched
          initialValues={{
            address1: (selectedLocation && selectedLocation.address1) || "",
            address2: (selectedLocation && selectedLocation.address2) || "",
            majorIntersection:
              (selectedLocation && selectedLocation.majorIntersection) || "",
            city: (selectedLocation && selectedLocation.city) || "",
            postalCode: (selectedLocation && selectedLocation.postalCode) || "",
            remainingPostalCode: "",
            phone: (selectedLocation && selectedLocation.phone) || "",
            phoneExt: (selectedLocation && selectedLocation.phoneExt) || "",
            saveLocation: !selectedLocation ? true : false,
            updateLocation: selectedLocation ? true : false,
            varietyItems: "",
          }}
          validateOnChange={false}
          validationSchema={CheckoutSchema}
          onSubmit={onSubmit}
        >
          {({ errors, values, handleSubmit, handleChange, setFieldValue }) => {
            return (
              <React.Fragment>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item lg={6}>
                      <TextField
                        fullWidth
                        label="Address 1"
                        placeholder="Address 1"
                        variant="outlined"
                        name="address1"
                        error={errors.address1}
                        helperText={errors.address1}
                        defaultValue={values.address1}
                        value={values.address1}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        label="Address 2"
                        fullWidth
                        placeholder="Address 2"
                        variant="outlined"
                        name="address2"
                        value={values.address2}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        label="Major Intersection"
                        fullWidth
                        placeholder="Major Intersection"
                        variant="outlined"
                        name="majorIntersection"
                        value={values.majorIntersection}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        label="City"
                        fullWidth
                        placeholder="City"
                        variant="outlined"
                        name="city"
                        value={values.city}
                        error={errors.city}
                        helperText={errors.city}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <Autocomplete
                        options={postalCodes}
                        value={values.postalCode}
                        inputValue={values.postalCode}
                        getOptionLabel={(postalCode: any) =>
                          postalCode ? `${postalCode.postalCode}` : ""
                        }
                        onChange={(_, selectedOption: any) => {
                          if (selectedOption) {
                            return setFieldValue(
                              "postalCode",
                              selectedOption.postalCode
                            );
                          }

                          setFieldValue("postalCode", "");
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant="outlined"
                            label="Postal Code"
                            placeholder="Postal Code"
                            error={!!errors.postalCode}
                            helperText={errors.postalCode}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item lg={3}>
                      <TextField
                        fullWidth
                        inputProps={{
                          maxLength: 3,
                        }}
                        disabled={
                          !values.postalCode ||
                          !(values.postalCode && values.postalCode.length === 3)
                        }
                        variant="outlined"
                        label="Fill Remaining Postal Code"
                        placeholder="Fill Remaining Postal Code"
                        name="remainingPostalCode"
                        error={errors.remainingPostalCode}
                        helperText={errors.remainingPostalCode}
                        value={values.remainingPostalCode}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        label="Phone"
                        fullWidth
                        placeholder="Phone"
                        variant="outlined"
                        name="phone"
                        error={errors.phone}
                        helperText={errors.phone}
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        label="Phone Ext"
                        fullWidth
                        placeholder="Phone Ext"
                        variant="outlined"
                        name="phoneExt"
                        value={values.phoneExt}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        label="Buzzer Code"
                        fullWidth
                        placeholder="Buzzer Code"
                        variant="outlined"
                        name="buzzerCode"
                        value={values.buzzerCode}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={12}>
                      {selectedLocation && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.updateLocation}
                              onChange={handleChange}
                              name="updateLocation"
                              color="primary"
                            />
                          }
                          label="Update Location"
                        />
                      )}

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.saveLocation}
                            disabled={values.updateLocation}
                            onChange={handleChange}
                            name="saveLocation"
                            color="primary"
                          />
                        }
                        label="Save Location"
                      />
                    </Grid>

                    <Grid item lg={12}>
                      <TextField
                        label="Variety Items"
                        fullWidth
                        multiline
                        rows={2}
                        placeholder="Variety Items"
                        helperText="The cost of variety item(s) are not included in the checkout total and also includes an additional $2.00 charge."
                        variant="outlined"
                        value={values.varietyItems}
                        onChange={handleChange}
                        name="varietyItems"
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <Divider />

                <CardContent>
                  <Paper variant="outlined" className={classes.checkoutCharges}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <Typography>
                          Subtotal: ${charges.subTotal.toFixed(2)}
                        </Typography>
                        <Typography>
                          Delivery Charge: ${charges.deliveryCharge.toFixed(2)}
                        </Typography>
                        <Typography>Tax: ${charges.tax.toFixed(2)}</Typography>
                      </Grid>

                      <Grid item className={classes.checkoutSubTotal}>
                        <Typography>
                          Total: ${charges.total.toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </CardContent>

                <Divider />

                <CardContent>
                  <Grid
                    container
                    direction="column"
                    alignItems="flex-end"
                    justify="flex-end"
                    spacing={1}
                  >
                    <Grid item lg={2}>
                      <Button
                        // TODO: Handle button disabling
                        // disable={}
                        onClick={handleSubmit}
                      >
                        Submit Order
                      </Button>
                    </Grid>

                    <Grid item lg={3}>
                      <Typography variant="subtitle2">
                        The estimated wait time is one hour.
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </React.Fragment>
            );
          }}
        </Formik>
      </Card>

      <Snackbar
        autoHideDuration={2000}
        id="snackbar_checkoutSuccess"
        open={snackbarOpen.open}
        message={snackbarOpen.message}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
    </React.Fragment>
  );
}

export default Checkout;
