const ADD_PRODUCT_TO_CART_TYPE = "ADD_PRODUCT_TO_CART";
const UPDATE_CART_ITEM_TYPE = "UPDATE_CART_ITEM";
const REMOVE_CART_ITEM_TYPE = "REMOVE_CART_ITEM";
const EMPTY_CART_ITEMS_TYPE = "EMPTY_CART_ITEMS";

function actionAddProductToCart(product, quantity, notes) {
  return {
    type: ADD_PRODUCT_TO_CART_TYPE,
    payload: {
      product,
      quantity,
      notes,
    },
  };
}

function actionUpdateSelectedCartItem(productId, fields) {
  return {
    type: UPDATE_CART_ITEM_TYPE,
    payload: {
      productId,
      ...fields,
    },
  };
}

function actionRemoveItemFromCart(productId) {
  return {
    type: REMOVE_CART_ITEM_TYPE,
    payload: {
      productId,
    },
  };
}

function actionEmptyCartItems() {
  return {
    type: EMPTY_CART_ITEMS_TYPE,
  };
}

const SET_IS_REORDER_TYPE = "SET_IS_REORDER";

function actionIsReorder() {
  return {
    type: SET_IS_REORDER_TYPE,
  };
}

export {
  actionAddProductToCart,
  actionEmptyCartItems,
  actionRemoveItemFromCart,
  actionUpdateSelectedCartItem,
  actionIsReorder,
  ADD_PRODUCT_TO_CART_TYPE,
  UPDATE_CART_ITEM_TYPE,
  REMOVE_CART_ITEM_TYPE,
  EMPTY_CART_ITEMS_TYPE,
  SET_IS_REORDER_TYPE,
};
