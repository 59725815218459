import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  SET_PRODUCT_CHARGES_TYPE,
  SAVE_ADDRESS_START_TYPE,
  SAVE_ADDRESS_SUCCESSFUL_TYPE,
  SAVE_ADDRESS_FAILED_TYPE,
  UPDATE_ADDRESS_START_TYPE,
  UPDATE_ADDRESS_SUCCESSFUL_TYPE,
  UPDATE_ADDRESS_FAILED_TYPE,
  LOAD_ADDRESSES_START_TYPE,
  LOAD_ADDRESSES_SUCCESSFUL_TYPE,
  LOAD_ADDRESSES_FAILED_TYPE,
  FETCH_COUPON_START_TYPE,
  FETCH_COUPON_SUCCESSFUL_TYPE,
  FETCH_COUPON_FAILED_TYPE,
  REMOVE_COUPON_TYPE,
  SET_PAYMENT_METHOD_TYPE,
} from "./actions";

import { LOGOUT_TYPE } from "../../store/actions";

const initialState = {
  charges: {},
  coupon: {},
  paymentMethod: "",
  address: "",
  addresses: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_CHARGES_TYPE: {
      const { charges, paymentMethod } = action.payload;

      return {
        ...state,
        charges: {
          ...charges,
        },
        paymentMethod,
      };
    }

    case SAVE_ADDRESS_START_TYPE: {
      return {
        ...state,
      };
    }

    case SAVE_ADDRESS_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        addresses: [...state.addresses, ...data],
      };
    }

    case SAVE_ADDRESS_FAILED_TYPE: {
      return {
        ...state,
      };
    }

    case UPDATE_ADDRESS_START_TYPE: {
      return {
        ...state,
      };
    }

    case UPDATE_ADDRESS_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      const updatedAddress = data[0];

      return {
        ...state,
        addresses: state.addresses.map((address) => {
          if (address.id === updatedAddress.id) {
            return updatedAddress;
          }

          return address;
        }),
      };
    }

    case UPDATE_ADDRESS_FAILED_TYPE: {
      return {
        ...state,
      };
    }

    case LOAD_ADDRESSES_START_TYPE: {
      return {
        ...state,
      };
    }

    case LOAD_ADDRESSES_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        addresses: [...data],
      };
    }

    case LOAD_ADDRESSES_FAILED_TYPE: {
      return {
        ...state,
      };
    }

    case FETCH_COUPON_START_TYPE: {
      return {
        ...state,
      };
    }

    case FETCH_COUPON_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        coupon: {
          ...data,
        },
      };
    }

    case FETCH_COUPON_FAILED_TYPE: {
      return {
        ...state,
      };
    }

    case REMOVE_COUPON_TYPE: {
      return {
        ...state,
        coupon: {},
      };
    }

    case SET_PAYMENT_METHOD_TYPE: {
      const { paymentMethod } = action.payload;

      return {
        ...state,
        paymentMethod,
      };
    }

    case LOGOUT_TYPE: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
}

const persistConfig = {
  key: "checkout",
  storage,
};

export default persistReducer(persistConfig, reducer);
