import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import "./App.css";
import AuthModal from "./components/auth";
import AuthedRoute from "./components/auth/authedRoute";
import Footer from "./components/footer";
import Cart from "./pages/Cart";
import AddToCartModal from "./pages/Cart/AddToCartModal";
import Checkout from "./pages/Checkout";
import Home from "./pages/Home";
import Contact from "./pages/Misc/Contact";
import Nav from "./pages/Nav";
import Products from "./pages/Products";
import Product from "./pages/Products/Product";
import Reviews from "./pages/Reviews";
import Signup from "./pages/Signup";
import Reorder from "./pages/Account/Reorder";
import Account from "./pages/Account/Account";
import Password from "./pages/Password";
import Forgot from "./pages/Password/Forgot";
import { AuthProvider } from "./providers/auth";
import { actionFetchPostalCodes } from "./store/PostalCodes/actions";

const WindowStyleWrapper = styled.div`
  min-height: 100vh;
  font-family: "Roboto Condensed", sans-serif;
`;

/* const FooterStyleWrapper = styled.div`
  background: white;
  height: 20vh;
`; */

function App() {
  const dispatch = useDispatch();

  const [authModalOpen, setAuthModalOpen] = useState(false);
  // refactor candidate: remove add to cart from App
  const [isAddToCartModalOpen, setAddToCartModalOpen] = useState(false);
  const [addToCartProduct, setAddToCartProduct] = useState({});

  // eslint-disable-next-line
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    dispatch(actionFetchPostalCodes());
  }, []);

  const openAuthModal = () => setAuthModalOpen(true);
  const closeAuthModal = () => setAuthModalOpen(false);
  const openAddToCartModal = () => setAddToCartModalOpen(true);
  const closeAddToCartModal = () => setAddToCartModalOpen(false);

  // enhancement candidate: after successful auth, open add to cart modal with product
  // refactor candidate: move router out of app
  // need to check historical orders against DB to make sure it's still available
  return (
    <AuthProvider>
      <WindowStyleWrapper>
        <Router>
          <Nav openAuthModal={openAuthModal} closeAuthModal={closeAuthModal} />
          <AuthModal
            isOpen={authModalOpen}
            closeModal={closeAuthModal}
            openAddToCartModal={openAddToCartModal}
            addToCartProduct={addToCartProduct}
            setAddToCartModalOpen={openAddToCartModal}
          />

          {isAddToCartModalOpen && (
            <AddToCartModal
              isOpen={isAddToCartModalOpen}
              closeModal={closeAddToCartModal}
              product={addToCartProduct}
              setSnackbarMessage={setSnackbarMessage}
            />
          )}

          <Container>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>

              <Route path="/products" component={Products} />

              <Route path="/product/:productName">
                <Product
                  openAuthModal={openAuthModal}
                  openAddToCartModal={openAddToCartModal}
                  setAddToCartProduct={setAddToCartProduct}
                />
              </Route>

              <Route path="/signup">
                <Signup />
              </Route>

              <Route path="/cart" component={AuthedRoute(Cart)} />
              <Route path="/checkout" component={AuthedRoute(Checkout)} />
              <Route path="/reviews" component={Reviews} />
              <Route path="/contact" component={Contact} />
              <Route path="/reorder" component={Reorder} />
              <Route path="/account" component={Account} />
              <Route path="/reset-password" component={Password} />
              <Route path="/forgot-password/:token" component={Forgot} />
            </Switch>
          </Container>
          <Footer />
        </Router>
      </WindowStyleWrapper>
    </AuthProvider>
  );
}

export default App;
