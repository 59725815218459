import { apiInstance } from "./../../config";

const GET_ORDERS_START_TYPE = "GET_ORDERS_START";
const GET_ORDERS_SUCCESSFUL_TYPE = "GET_ORDERS_SUCCESSFUL";
const GET_ORDERS_FAILED_TYPE = "GET_ORDERS_FAILED";

function actionGetOrders() {
  return {
    types: [
      GET_ORDERS_START_TYPE,
      GET_ORDERS_SUCCESSFUL_TYPE,
      GET_ORDERS_FAILED_TYPE,
    ],
    promise: apiInstance.get("/order"),
  };
}

const GET_ACCOUNT_START_TYPE = "GET_ACCOUNT_START";
const GET_ACCOUNT_SUCCESSFUL_TYPE = "GET_ACCOUNT_SUCCESSFUL";
const GET_ACCOUNT_FAILED_TYPE = "GET_ACCOUNT_FAILED";

function actionGetAccount() {
  return {
    types: [
      GET_ACCOUNT_START_TYPE,
      GET_ACCOUNT_SUCCESSFUL_TYPE,
      GET_ACCOUNT_FAILED_TYPE,
    ],
    promise: apiInstance.get("/account"),
  };
}

const EDIT_ACCOUNT_START_TYPE = "EDIT_ACCOUNT_START";
const EDIT_ACCOUNT_SUCCESSFUL_TYPE = "EDIT_ACCOUNT_SUCCESSFUL";
const EDIT_ACCOUNT_FAILED_TYPE = "EDIT_ACCOUNT_FAILED";

function actionEditAccount(accountData) {
  return {
    types: [
      EDIT_ACCOUNT_START_TYPE,
      EDIT_ACCOUNT_SUCCESSFUL_TYPE,
      EDIT_ACCOUNT_FAILED_TYPE,
    ],
    promise: apiInstance.patch("/account", {
      firstName: accountData.firstName,
      lastName: accountData.lastName,
      phone: accountData.phone,
      phoneExt: accountData.phoneExt,
      email: accountData.email,
      accountId: accountData.id,
    }),
  };
}

export {
  EDIT_ACCOUNT_FAILED_TYPE,
  EDIT_ACCOUNT_SUCCESSFUL_TYPE,
  EDIT_ACCOUNT_START_TYPE,
  GET_ORDERS_FAILED_TYPE,
  GET_ORDERS_START_TYPE,
  GET_ORDERS_SUCCESSFUL_TYPE,
  GET_ACCOUNT_START_TYPE,
  GET_ACCOUNT_SUCCESSFUL_TYPE,
  GET_ACCOUNT_FAILED_TYPE,
  actionGetAccount,
  actionEditAccount,
  actionGetOrders,
};
