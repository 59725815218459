import config, { apiInstance } from "../../config";

const FETCH_HOURS_START_TYPE = "FETCH_HOURS_START";
const FETCH_HOURS_SUCCESSFUL_TYPE = "FETCH_HOURS_SUCCESSFUL";
const FETCH_HOURS_FAILED_TYPE = "FETCH_HOURS_FAILED";

function actionFetchHours() {
  return {
    types: [
      FETCH_HOURS_START_TYPE,
      FETCH_HOURS_SUCCESSFUL_TYPE,
      FETCH_HOURS_FAILED_TYPE,
    ],
    promise: apiInstance.get(`${config.api_url}/hours`),
  };
}

export {
  actionFetchHours,
  FETCH_HOURS_START_TYPE,
  FETCH_HOURS_SUCCESSFUL_TYPE,
  FETCH_HOURS_FAILED_TYPE,
};
