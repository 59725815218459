import { Grid, TextField, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";

// Components
import { Button } from "../../components/inputs";

import {
  actionRemoveItemFromCart,
  actionUpdateSelectedCartItem,
} from "./../Cart/actions";
import { CartItemWrapper, useStyles } from "./styled";

function CartItem({ product, quantity, notes }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { id: productID, product_name: productName, price } = product;

  const onChangeCartItemQuantity = ({ target }) => {
    let newQuantity = parseInt(target.value, 10);

    if (isNaN(newQuantity)) {
      newQuantity = 0;
    }

    dispatch(
      actionUpdateSelectedCartItem(productID, {
        quantity: newQuantity,
      })
    );
  };

  const onChangeCartItemNote = ({ target }) =>
    dispatch(
      actionUpdateSelectedCartItem(productID, {
        notes: target.value,
      })
    );

  const removeCartItem = () => dispatch(actionRemoveItemFromCart(productID));

  return (
    <CartItemWrapper>
      <Grid container alignItems="center" className={classes.cartItemRoot}>
        <Grid item lg={5}>
          <Typography variant="subtitle1">{productName}</Typography>

          <Typography variant="subtitle2">
            {quantity} x {price}
          </Typography>
        </Grid>

        <Grid container lg={5} justify="flex-end">
          <TextField
            fullWidth
            value={notes}
            multiline
            rowsMax={2}
            label="Notes"
            placeholder="Notes"
            onChange={onChangeCartItemNote}
            variant="outlined"
          />
        </Grid>

        <Grid container lg={1} justify="center">
          <TextField
            value={quantity}
            type="number"
            label="Quantity"
            placeholder="Quantity"
            variant="outlined"
            onChange={onChangeCartItemQuantity}
            InputProps={{
              inputProps: {
                max: 100,
                min: 0,
              },
            }}
          />
        </Grid>

        <Grid container lg={1} justify="flex-end">
          <Button onClick={removeCartItem}>Remove</Button>
        </Grid>
      </Grid>
    </CartItemWrapper>
  );
}

CartItem.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    notes: PropTypes.string,
  }),
};

export { CartItem };
