import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { apiInstance } from "../../config";

import { login } from "../../services/auth";
import AuthContext from "../../providers/auth";

import Login from "./login";
import ForgotPassword from "./forgotPassword";

import { ModalExit, modalPosition } from "../styles/modal";

const INIT_USER_STATE = {
  email: "",
  password: "",
};

function AuthModal(props) {
  const { isOpen, closeModal, addToCartProduct, setAddToCartModalOpen } = props;

  const { setAuth } = useContext(AuthContext);
  const [authError, setAuthError] = useState("");
  const [toggleForgot, setToggleForgot] = useState(false);
  const [toggleCheckEmail, setToggleCheckEmail] = useState(false);

  const [userInformationState, setUserInformationState] = useState(
    INIT_USER_STATE
  );

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setUserInformationState({
      ...userInformationState,
      [name]: value,
    });
  };

  const submitLogin = async (e) => {
    e.preventDefault();
    const { email, password } = userInformationState;

    try {
      await login(email, password);
    } catch (error) {
      setAuthError(error);
      return;
    }

    setAuth({ state: "login" });
    setAuthError("");
    clearAndClose();
    if (addToCartProduct.id) {
      setAddToCartModalOpen();
    }
  };

  const clearAndClose = () => {
    setUserInformationState(INIT_USER_STATE);
    setAuthError("");
    closeModal();
  };

  const submitEmail = async (e) => {
    e.preventDefault();
    await apiInstance.post("/account/forgot-password", {
      email: userInformationState.email,
    });
    setToggleCheckEmail(true);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={clearAndClose}
      ariaHideApp={false}
      style={modalPosition}
    >
      <ModalExit onClick={clearAndClose}>X</ModalExit>
      {toggleForgot ? (
        <ForgotPassword
          toggleForgot={() => {
            setToggleForgot(false);
            setToggleCheckEmail(false);
          }}
          submitEmail={submitEmail}
          checkEmail={toggleCheckEmail}
          userInformationState={userInformationState}
          handleChange={handleChange}
        />
      ) : (
        <Login
          userInformationState={userInformationState}
          handleChange={handleChange}
          submitLogin={submitLogin}
          clearAndClose={clearAndClose}
          toggleForgot={() => {
            setToggleForgot(true);
            setToggleCheckEmail(false);
          }}
        />
      )}
      <div> {authError} </div>
    </Modal>
  );
}

AuthModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  addToCartProduct: PropTypes.shape({
    id: PropTypes.string,
  }),
  setAddToCartModalOpen: PropTypes.func.isRequired,
};

export default AuthModal;
