import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../../constants";
import { textInput, labelStyles } from "../styles/form";
import { Row, Column } from "../utils";

const IncrementStyleWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ArrowUp = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid ${colors.redText};
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 100;
`;

const ArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid ${colors.redText};
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  z-index: 100;
`;

function Incrementer(props) {
  const { onClick, onChange, name, value, label } = props;

  return (
    <Row>
      <Column size={2}>
        <label htmlFor={name} style={labelStyles}>
          {label}
        </label>
      </Column>
      <Column size={4}>
        <IncrementStyleWrapper>
          <input
            type="text"
            name={name}
            style={textInput}
            onChange={onChange}
            value={value}
          />
          <ArrowUp onClick={() => onClick(1)} />
          <ArrowDown onClick={() => onClick(-1)} />
        </IncrementStyleWrapper>
      </Column>
      <Column size={6} />
    </Row>
  );
}

Incrementer.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string,
};

export default Incrementer;
