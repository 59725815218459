import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import axios from "axios";

import { ProductDetailCard } from "./ProductDetailCard";
import config from "../../config";

function Product({ openAuthModal, openAddToCartModal, setAddToCartProduct }) {
  const [products, setProducts] = useState([]);
  const { productName } = useParams();

  useEffect(() => {
    (async () => {
      const products = await axios.get(
        `${config.api_url}/product/${productName}`
      );
      setProducts(products.data);
    })();
  }, [productName]);

  return (
    <Grid container spacing={3}>
      {products.map((product) => (
        <ProductDetailCard
          key={product.id}
          product={product}
          openAuthModal={openAuthModal}
          openAddToCartModal={openAddToCartModal}
          setAddToCartProduct={setAddToCartProduct}
        />
      ))}
    </Grid>
  );
}

Product.propTypes = {
  openAuthModal: PropTypes.func.isRequired,
  openAddToCartModal: PropTypes.func.isRequired,
  setAddToCartProduct: PropTypes.func.isRequired,
};

export default Product;
