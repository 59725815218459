import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formSectionDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formSectionTitle: {
    fontSize: "18px",
    fontWeight: 500,
    fontFamily: "Roboto, sans-serif",
    marginBottom: theme.spacing(2),
  },
  postalCodeCheck: {
    display: "flex",
  },
  postalCodeCheckButton: {
    height: "40px",
    marginLeft: theme.spacing(2),
  },
  postalCodeCheckMessage: {
    alignItems: "center",
    height: "40px",
    padding: "0 32px 0 16px",
    marginLeft: theme.spacing(2),
  },
}));

export { useStyles };
