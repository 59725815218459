import {
  FETCH_HOURS_START_TYPE,
  FETCH_HOURS_SUCCESSFUL_TYPE,
  FETCH_HOURS_FAILED_TYPE,
} from "./actions";

const initialState = {
  defaultHours: [],
  todayHours: {},
  // NOTE: Next day hours can be null IF all store days are closed!
  nextDayHours: null,
  isOpen: false,
  isFetchingHours: false,
  closedAllDay: false
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_HOURS_START_TYPE: {
      return {
        ...state,
        isFetchingHours: true,
      };
    }

    case FETCH_HOURS_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        isFetchingHours: false,
        defaultHours: data.defaultHours,
        nextDayHours: data.nextDayHours,
        todayHours: data.todayHours,
        isOpen: data.isOpen,
        closedAllDay: data.closedAllDay
      };
    }

    case FETCH_HOURS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingHours: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
