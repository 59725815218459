import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistStore } from "redux-persist";

import { promiseMiddleware } from "./promiseMiddleware";
import rootReducer from "./rootReducer";

// TODO: Add prod handling
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(promiseMiddleware))
);

const persistor = persistStore(store);

export { store, persistor };
