import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  ADD_PRODUCT_TO_CART_TYPE,
  EMPTY_CART_ITEMS_TYPE,
  UPDATE_CART_ITEM_TYPE,
  REMOVE_CART_ITEM_TYPE,
  SET_IS_REORDER_TYPE,
} from "./actions";

import { LOGOUT_TYPE } from "../../store/actions";

const initialState = {
  cartItems: [],
  selectedCartItems: {},
  isReorder: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_PRODUCT_TO_CART_TYPE: {
      const { product, quantity, notes } = action.payload;

      return {
        ...state,
        selectedCartItems: {
          ...state.selectedCartItems,
          [product.id]: {
            product,
            quantity,
            notes,
          },
        },
      };
    }

    case UPDATE_CART_ITEM_TYPE: {
      const { productId, ...rest } = action.payload;

      return {
        ...state,
        selectedCartItems: {
          ...state.selectedCartItems,
          [productId]: {
            ...state.selectedCartItems[productId],
            ...rest,
          },
        },
      };
    }

    case REMOVE_CART_ITEM_TYPE: {
      const { productId } = action.payload;
      // eslint-disable-next-line
      const { [productId]: itemToOmit, ...rest } = state.selectedCartItems;

      return {
        ...state,
        selectedCartItems: {
          ...rest,
        },
      };
    }

    case EMPTY_CART_ITEMS_TYPE: {
      return {
        ...initialState,
      };
    }

    case LOGOUT_TYPE: {
      return {
        ...initialState,
      };
    }

    case SET_IS_REORDER_TYPE: {
      return {
        ...state,
        isReorder: true,
      };
    }

    default: {
      return state;
    }
  }
}

const persistConfig = {
  key: "cartItems",
  storage,
};

export default persistReducer(persistConfig, reducer);
