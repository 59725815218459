// eslint-disable-next-line
const promiseMiddleware = (store) => (next) => (action) => {
  const { promise, types } = action;

  if (!promise) {
    return next(action);
  }

  const [LOADING, RESOLVED, REJECTED] = types;

  next({
    type: LOADING,
  });

  return promise.then(
    (result) =>
      next({
        type: RESOLVED,
        payload: result,
      }),
    (error) =>
      next({
        type: REJECTED,
        error,
      })
  );
};

export { promiseMiddleware };
