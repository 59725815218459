import config, { apiInstance } from "../../config";

const FETCH_REVIEWS_START_TYPE = "FETCH_REVIEWS_START";
const FETCH_REVIEWS_SUCCESSFUL_TYPE = "FETCH_REVIEWS_SUCCESSFUL";
const FETCH_REVIEWS_FAILED_TYPE = "FETCH_REVIEWS_FAILED";

function actionFetchReviews() {
  return {
    types: [
      FETCH_REVIEWS_START_TYPE,
      FETCH_REVIEWS_SUCCESSFUL_TYPE,
      FETCH_REVIEWS_FAILED_TYPE,
    ],
    promise: apiInstance.get(`${config.api_url}/review`),
  };
}

const SUBMIT_REVIEW_START_TYPE = "SUBMIT_REVIEW_START";
const SUBMIT_REVIEW_SUCCESSFUL_TYPE = "SUBMIT_REVIEW_SUCCESSFUL";
const SUBMIT_REVIEW_FAILED_TYPE = "SUBMIT_REVIEW_FAILED";

function actionSubmitReview(reviewData) {
  return {
    types: [
      SUBMIT_REVIEW_START_TYPE,
      SUBMIT_REVIEW_SUCCESSFUL_TYPE,
      SUBMIT_REVIEW_FAILED_TYPE,
    ],
    promise: apiInstance.post("/review", {
      review: reviewData.review,
      stars: reviewData.stars,
    }),
  };
}

export {
  actionFetchReviews,
  actionSubmitReview,
  FETCH_REVIEWS_START_TYPE,
  FETCH_REVIEWS_SUCCESSFUL_TYPE,
  FETCH_REVIEWS_FAILED_TYPE,
  SUBMIT_REVIEW_START_TYPE,
  SUBMIT_REVIEW_SUCCESSFUL_TYPE,
  SUBMIT_REVIEW_FAILED_TYPE,
};
