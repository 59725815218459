import React from "react";
import { AppBar, Grid, Toolbar, Container } from "@material-ui/core";
import { Link } from "react-router-dom";

import { useStyles } from "./styled";

function Footer() {
  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar>
        <Container>
          <Grid container justify={"space-between"}>
            <Grid item lg={4}>
              © BDDeliveries.com 2021
            </Grid>
            <Grid item lg={4} />
            <Grid item lg={4}>
              <div className={classes.appFooterLinks}>
                  <Link
                    to={{
                      pathname: "/how-it-works",
                    }}
                    className={classes.listItemTextLink}
                  >
                    How It Works
                  </Link>
                  <Link
                    to={{ pathname: "/terms-of-service" }}
                    className={classes.listItemTextLink}
                  >
                    Terms of Service
                  </Link>
                  <Link
                    to={{ pathname: "/contact" }}
                    className={classes.listItemTextLink}
                  >
                    Contact Us
                  </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
