import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    marginTop: theme.spacing(2),
  },
  appFooterLinks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  listItemTextLink: {
    marginLeft: theme.spacing(2),
    textDecoration: "none",
    color: theme.palette.common.white,
  },
}));

export { useStyles };
