const CHARGES = {
  tax: 0.0,
  subTotal: 0.0,
  deliveryCharge: 9.50,
  surcharge: 0.0,
  couponDiscount: 0.0,
  total: 0.0,
};

const SURCHARGES = {
  visa: 5.0,
  mastercard: 5.0,
  interac: 2.0,
  cash: 0.0,
};

const doMath = (num) => Math.round(((num) + Number.EPSILON) * 100) / 100;

const createCharges = (selectedCartItems, paymentMethod, coupon) => {
  const subTotal =
    selectedCartItems.length > 0
      ? selectedCartItems
          .map(({ product, quantity }) => parseFloat(product.price) * quantity)
          .reduce((acc, price) => acc + price)
      : 0;

  const couponDiscount = coupon.discount ? doMath(parseFloat(coupon.discount)) : 0.00;

  const deliveryCharge = doMath(CHARGES.deliveryCharge - couponDiscount);

  const tax = doMath(deliveryCharge * 0.13);

  const surchargeMultiplier =
    (paymentMethod === "mastercard" || paymentMethod === "visa" || paymentMethod === "interac") &&
    subTotal > 100
      ? Math.floor(subTotal / 100) + 1
      : 1;

  const surcharge = doMath(SURCHARGES[paymentMethod] * surchargeMultiplier) || 0.00;
  const total = doMath(deliveryCharge + subTotal + surcharge + tax);

  return {
    tax,
    total,
    surcharge,
    subTotal,
    deliveryCharge,
    couponDiscount,
  };
};

export { createCharges };
