import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { colors } from "../../constants";

const WarningBoxStyles = styled.div`
  color: ${colors.warningText};
  background-color: ${colors.warningBackground};
  border: 1px solid ${colors.warningBorder};
  padding: 15px;
  margin-top: 16px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  reviewItemsContentRoot: {
    paddingTop: 0,
  },
  reviewItemContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  reviewItemReview: {
    marginBottom: theme.spacing(2),
  },
}));

export { WarningBoxStyles, useStyles };
