import styled from "styled-components";

export const ModalHeader = styled.div`
  border-bottom: 1px solid grey;
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

export const ModalExit = styled.div`
  float: right;
  cursor: pointer;
  color: grey;
`;

export const ModalFooter = styled.div`
  border-top: 1px solid grey;
  padding-top: 15px;
  margin-top: 15px;
`;

export const modalPosition = {
  content: {
    top: "15%",
    left: "50%",
    right: "none",
    bottom: "none",
    height: "auto",
    minWidth: "60vw",
    transform: "translate(-50%)",
    maxHeight: "500px",
    overflow: "wrap",
  },
};
