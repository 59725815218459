import { makeStyles } from "@material-ui/core/styles";

import { colors } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paddedItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  imageText: {
    color: colors.redText,
    verticalAlign: "middle",
    display: "inline",
    paddingLeft: theme.spacing(2),
  },
  imageBlock: {
    verticalAlign: "middle",
  },
  imageCard: {
    borderColor: colors.darkGreyText,
    backgroundColor: "black",
  },
  stepCard: {
    border: "solid 5px",
    borderColor: colors.darkGreyText,
    backgroundColor: "black",
    color: "white",
    textAlign: "center",
    position: "relative",
    fontWeight: "bold",
    height: "130px",
  },
  stepCardContent: {
    // height of card - padding (16px) * 2
    lineHeight: "102px",
  },
  stepCardText: {
    verticalAlign: "middle",
    display: "inline-block",
  },
  stepCardNumber: {
    position: "absolute",
    top: "0",
    left: "0",
    backgroundColor: colors.darkGreyText,
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    color: colors.redText,
    border: "solid 5px",
    borderColor: colors.darkGreyText,
    borderBottomRightRadius: "4px",
    paddingRight: "4px",
  },
}));

export { useStyles };
