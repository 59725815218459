import React from "react";
import PropTypes from "prop-types";
import { Button as MuiButton } from "@material-ui/core";

export default function Button(props) {
  const { children, height, style, width, onClick, ...rest } = props;

  const styleObj = style || {};

  return (
    <MuiButton
      color="primary"
      variant="contained"
      disableElevation
      style={{ height, width, ...styleObj }}
      {...rest}
      onClick={onClick}
    >
      {children}
    </MuiButton>
  );
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  width: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
