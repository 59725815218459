import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from "@material-ui/core";
import Ratings from "react-ratings-declarative";
import moment from "moment";

import { useStyles, WarningBoxStyles } from "./styled";

function ReviewsSection(props) {
  const classes = useStyles();
  const reviewsData = props.reviewsData;
  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader title="B&D Deliveries Inc Reviews" />
      <CardContent className={classes.reviewItemsContentRoot}>
        <Grid container>
          <Grid item lg={12}>
            {!reviewsData.length && (
              <WarningBoxStyles>
                {" "}
                There are no reviews! Leave the first!{" "}
              </WarningBoxStyles>
            )}
            {reviewsData.map((review) => (
              <div key={review.id} className={classes.reviewItemContent}>
                <Grid container spacing={3}>
                  <Grid item lg={10}>
                    <Typography variant="h6">
                      {`${review.firstName} wrote at ${moment(
                        review.createdAt
                      ).format("YYYY-MM-DD, HH:MM a")}`}
                    </Typography>
                  </Grid>
                  <Grid item lg={2}>
                    <Ratings
                      rating={review.stars}
                      widgetRatedColors="red"
                      widgetDimensions="15px"
                      widgetSpacings="3px"
                    >
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                    </Ratings>
                  </Grid>
                  <Grid item lg={12}>
                    <div className={classes.reviewItemReview}>
                      {review.review}
                    </div>

                    {reviewsData.length > 0 ? <Divider /> : ""}
                  </Grid>
                </Grid>
              </div>
            ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ReviewsSection;
