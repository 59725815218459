import { Grid, TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import isEmpty from "lodash.isempty";
import { useDispatch, useSelector } from "react-redux";

// Components
import { Button } from "../../components/inputs";

import { actionFetchCoupon, actionRemoveCoupon } from "./../Checkout/actions";
import { createCharges } from "./helpers";
import { Embolden, useStyles } from "./styled";

function ChargesSection({ selectedPaymentMethod }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [couponCode, setCoupon] = useState("");

  const selectedCartItems = Object.values(
    useSelector((state) => state.cart.selectedCartItems)
  );

  const coupon = useSelector((state) => state.checkout.coupon);

  const {
    total,
    tax,
    deliveryCharge,
    subTotal,
    surcharge,
  } = createCharges(selectedCartItems, selectedPaymentMethod, coupon);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <div className={classes.invoiceStyleWrapper}>
          Subtotal: <Embolden> ${subTotal.toFixed(2)} </Embolden>
          <br />
          Payment Surcharges: <Embolden> ${surcharge.toFixed(2)} </Embolden>
          <br />
          Delivery Charge{coupon.discount && " (with coupon)"}: <Embolden> ${deliveryCharge.toFixed(2)} </Embolden>
          <br />
          Tax: <Embolden> ${tax.toFixed(2)} </Embolden>
          <br />
          <br />
          Total: <Embolden noItalic> ${total.toFixed(2)} </Embolden>
        </div>
      </Grid>

      <Grid item>
        <Grid container alignItems="center" justify="flex-end" spacing={2}>
          <Grid lg={6} item>
            <TextField
              value={couponCode}
              fullWidth
              size="medium"
              name="couponCode"
              label="Coupon"
              placeholder="Enter a valid coupon code"
              onChange={({ target }) => setCoupon(target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <Button
              size="small"
              onClick={() => dispatch(actionFetchCoupon(couponCode))}
            >
              Apply Coupon
            </Button>
            &nbsp;
            <Button
              size="small"
              disabled={isEmpty(coupon)}
              onClick={() => dispatch(actionRemoveCoupon())}
            >
              Remove Coupon
            </Button>
          </Grid>
          Enter the coupon &quot;bdonline&quot; and save $0.50 off delivery
          charages.
        </Grid>
      </Grid>
    </Grid>
  );
}

ChargesSection.propTypes = {
  chargesObject: PropTypes.shape({
    total: PropTypes.number,
    tax: PropTypes.number,
    deliveryCharge: PropTypes.number,
    subTotal: PropTypes.number,
    surcharge: PropTypes.number,
    couponDiscount: PropTypes.number,
  }),
};

export { ChargesSection };
