import { fade, makeStyles } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";
import blue from "@material-ui/core/colors/lightBlue";
import styled from "styled-components";

// Utilities
import { colors } from "../../constants";

const NavUpperStyleWrapper = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
`;

const NavLowerStyleWrapper = styled.div`
  background-color: black;
  height: auto;
`;

const UserActionWrapper = styled.div`
  text-align: right;
`;

const ForceRight = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ActionText = styled.div`
  color: ${colors.redText};
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
`;

const RedText = styled.div`
  color: ${colors.redText};
  display: inline-block;
`;

const HoverText = styled.div`
  &:hover {
    background-color: ${colors.redText};
    color: white;
  }
`;

const BoldText = styled.div`
  font-weight: bold;
  font-size: 22px;
  padding: 15px;
`;

const CenterTextStyles = styled.div`
  text-align: center;
  font-weight: bold;
  line-height: 20px;
`;

const CouponAlertStyleWrapper = styled.div`
  background-color: black;
  color: white;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
`;

// Store open indicator
const OpenIndicator = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;
  color: ${({ open }) => open ? blue[300] : orange[300]};

  svg {
    margin-right: 8px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.common.black,
  },
  listItemText: {
    marginRight: theme.spacing(3),
  },
  listItemTextLink: {
    textDecoration: "none",
    color: theme.palette.common.white,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: 240,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 240,
  },
  drawerListItemText: {
    paddingLeft: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  menuButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export {
  CenterTextStyles,
  CouponAlertStyleWrapper,
  NavUpperStyleWrapper,
  NavLowerStyleWrapper,
  UserActionWrapper,
  ForceRight,
  ActionText,
  RedText,
  HoverText,
  BoldText,
  OpenIndicator,
  useStyles,
};
