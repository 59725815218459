import React from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
} from "@material-ui/core";
import Ratings from "react-ratings-declarative";
import * as Yup from "yup";
import { Formik } from "formik";

// Components
import { Button } from "../../components/inputs";

import { useStyles } from "./styled";

const ReviewSchema = Yup.object().shape({
  review: Yup.string().required("Required"),
  stars: Yup.string().required("Required"),
});

function ReviewAdd(props) {
  const classes = useStyles();
  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader title="Write a Review" />
      <Formik
        enableReinitialize
        initialTouched
        validationSchema={ReviewSchema}
        onSubmit={async (values, { resetForm }) => {
          await props.submitReview(values);
          resetForm();
        }}
        initialValues={{
          review: "",
          stars: 0,
        }}
      >
        {({ errors, values, handleSubmit, handleChange, setFieldValue }) => {
          return (
            <React.Fragment>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={6}>
                    <Ratings
                      rating={values.stars}
                      widgetRatedColors="red"
                      widgetDimensions="30px"
                      name="stars"
                      changeRating={(value) => setFieldValue("stars", value)}
                    >
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                      <Ratings.Widget />
                    </Ratings>
                  </Grid>
                  <Grid item lg={12}>
                    <TextField
                      fullWidth
                      label="Review"
                      placeholder="Review"
                      variant="outlined"
                      name="review"
                      error={errors.review}
                      helperText={errors.review}
                      value={values.review}
                      onChange={handleChange}
                      multiline
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-end"
                  justify="flex-end"
                  spacing={1}
                >
                  <Grid item lg={2}>
                    <Button onClick={handleSubmit}>Submit</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </React.Fragment>
          );
        }}
      </Formik>
    </Card>
  );
}

export default ReviewAdd;
