import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { colors } from "../../constants";

const WarningBoxStyles = styled.div`
  color: ${colors.warningText};
  background-color: ${colors.warningBackground};
  border: 1px solid ${colors.warningBorder};
  padding: 15px;
  margin-top: 16px;
`;

const CartItemWrapper = styled.div`
  border-bottom: 1px solid ${colors.borderGrey};
`;

const ItemNameStyles = styled.div`
  font-weight: bold;
  color: ${colors.darkGreyText};
`;

const RadioItemStyles = styled.div`
  display: inline-flex;
  margin: 8px 0;
  width: 100%;

  label {
    display: block;
  }
`;

const RadioItemDescriptionStyles = styled.div`
  margin-top: 4px;
  color: grey;
`;

const InvoiceTopStyleWrapper = styled.div`
  background-color: ${colors.lightGrey};
  text-align: right;
  padding: 15px;
`;

const Embolden = styled.div`
  font-weight: bold;
  ${(props) => (props.noItalic ? "font-style: none;" : "font-style: italic;")}
  margin-left: 3px;
  display: inline;
`;

const CouponSectionStyleWrapper = styled.div`
  border-top: 1px solid ${colors.borderGrey};
  margin-top: 15px;
  padding-top: 15px;
  width: 100%;
  display: inline-block;
`;

const CouponSectionDividerWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  cartItemRoot: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  cartEmptyButton: {
    marginRight: theme.spacing(1),
  },
  cartItemsContentRoot: {
    paddingTop: 0,
  },
  invoiceStyleWrapper: {
    backgroundColor: colors.mediumGrey,
    textAlign: "right",
    lineHeight: 1.3,
    padding: "15px",
  },
}));

export {
  WarningBoxStyles,
  CartItemWrapper,
  ItemNameStyles,
  RadioItemStyles,
  RadioItemDescriptionStyles,
  InvoiceTopStyleWrapper,
  Embolden,
  CouponSectionStyleWrapper,
  CouponSectionDividerWrapper,
  useStyles,
};
