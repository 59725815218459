import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "../../constants";

const SnackbarPositionWrapper = styled.div`
  position: fixed;
  ${(props) => (props.isOpen ? "bottom: 0px;" : "bottom: -100px;")}
  left: 50%;
  transition: bottom 500ms ease-in;
`;

const SnackbarStyleWrapper = styled.div`
  width: 100%;
  margin-left: -50%;
  background-color: ${colors.redText};
  color: white;
  text-align: center;
  border: 1px solid ${colors.orange};
`;

const SnackbarTextWrapper = styled.div`
  padding: 20px;
  vertical-align: middle;
`;

const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

function Snackbar(props) {
  const { message, setSnackbarMessage } = props;

  const [isOpen, setIsOpen] = useState(false);

  useDidMountEffect(() => {
    if (message !== "") {
      setIsOpen(true);
      const timer = setTimeout(() => {
        setIsOpen(false);
      }, 2500);
      const reset = setTimeout(() => {
        setSnackbarMessage("");
      }, 3000);
      return () => {
        clearTimeout(timer);
        clearTimeout(reset);
      };
    }
    return;
  }, [message]);

  return (
    <SnackbarPositionWrapper isOpen={isOpen}>
      <SnackbarStyleWrapper>
        <SnackbarTextWrapper>{message}</SnackbarTextWrapper>
      </SnackbarStyleWrapper>
    </SnackbarPositionWrapper>
  );
}

Snackbar.propTypes = {
  message: PropTypes.string,
  setSnackbarMessage: PropTypes.func.isRequired,
};

export default Snackbar;
