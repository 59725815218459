import React, { useState } from "react";
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";

// Components
import { Button } from "../../components/inputs";

import { apiInstance } from "../../config";
import { useStyles } from "./styled";
import { Formik } from "formik";

function Contact() {
  const classes = useStyles();
  const [contactAttempting, setContactAttempting] = useState(false);
  // TODO: Display contact error somewhere
  const [, setContactError] = useState(null);

  const submitEmail = async (emailData) => {
    setContactAttempting(true);
    setContactError(null);
    try {
      await apiInstance.post("/contact", emailData);
    } catch (error) {
      setContactError("Could not send contact email.");
    }
    setContactAttempting(false);
    return;
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader title="Contact Us" />
      <CardContent>
        <Grid container className={classes.mainText}>
          <Grid item lg={12}>
            Give us a call or fill out the form. A B&D Deliveries Inc.
            representative will get back to you as soon as possible!
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={3}>
            <Grid
              container
              direction="column"
              justify={"space-between"}
              spacing={2}
            >
              <Grid item>
                <Typography variant="subtitle2">Cambridge & Guelph:</Typography>
                519-654-2437
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">
                  Kitchener & Waterloo:
                </Typography>
                519-746-4910
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Email:</Typography>
                info@bddeliveries.ca
              </Grid>
              <Grid item>
                <Typography variant="subtitle2">Mailing Address:</Typography>
                900 Guelph Street #403
                <br />
                Kitchener, ON N2H 5Z6
                <br />
                Canada
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={9}>
            <Formik
              onSubmit={async (values, { resetForm }) => {
                await submitEmail(values);
                resetForm();
              }}
              initialValues={{
                name: "",
                email: "",
                message: "",
              }}
            >
              {({ values, handleSubmit, handleChange }) => {
                return (
                  <Grid container spacing={2} justify={"flex-end"}>
                    <Grid item lg={12}>
                      <TextField
                        fullWidth
                        label="Name"
                        placeholder="Name"
                        variant="outlined"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        placeholder="Email"
                        variant="outlined"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={12}>
                      <TextField
                        fullWidth
                        label="Message"
                        placeholder="Message"
                        variant="outlined"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        multiline
                      />
                    </Grid>
                    <Grid item>
                      {contactAttempting ? (
                        <CircularProgress color="primary" />
                      ) : (
                        <Button width="100px" onClick={handleSubmit}>Submit</Button>
                      )}
                    </Grid>
                  </Grid>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default Contact;
