import { apiInstance } from "./../../config";

const SET_PRODUCT_CHARGES_TYPE = "SET_PRODUCT_CHARGES";

function actionSetProductCharges(charges, paymentMethod) {
  return {
    type: SET_PRODUCT_CHARGES_TYPE,
    payload: {
      charges,
      paymentMethod,
    },
  };
}

const SAVE_ADDRESS_START_TYPE = "SAVE_ADDRESS_START";
const SAVE_ADDRESS_SUCCESSFUL_TYPE = "SAVE_ADDRESS_SUCCESSFUL";
const SAVE_ADDRESS_FAILED_TYPE = "SAVE_ADDRESS_FAILED";

function actionSaveAddress(addressFields) {
  return {
    types: [
      SAVE_ADDRESS_START_TYPE,
      SAVE_ADDRESS_SUCCESSFUL_TYPE,
      SAVE_ADDRESS_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/address`, addressFields),
  };
}

const UPDATE_ADDRESS_START_TYPE = "UPDATE_ADDRESS_START";
const UPDATE_ADDRESS_SUCCESSFUL_TYPE = "UPDATE_ADDRESS_SUCCESSFUL";
const UPDATE_ADDRESS_FAILED_TYPE = "UPDATE_ADDRESS_FAILED";

function actionUpdateAddress(addressFields) {
  return {
    types: [
      UPDATE_ADDRESS_START_TYPE,
      UPDATE_ADDRESS_SUCCESSFUL_TYPE,
      UPDATE_ADDRESS_FAILED_TYPE,
    ],
    promise: apiInstance.put(`/address`, addressFields),
  };
}

const LOAD_ADDRESSES_START_TYPE = "LOAD_ADDRESSES_START";
const LOAD_ADDRESSES_SUCCESSFUL_TYPE = "LOAD_ADDRESSES_SUCCESSFUL";
const LOAD_ADDRESSES_FAILED_TYPE = "LOAD_ADDRESSES_FAILED";

function actionLoadAddresses() {
  return {
    types: [
      LOAD_ADDRESSES_START_TYPE,
      LOAD_ADDRESSES_SUCCESSFUL_TYPE,
      LOAD_ADDRESSES_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/address`),
  };
}

const CREATE_ORDER_START_TYPE = "CREATE_ORDER_START";
const CREATE_ORDER_SUCCESSFUL_TYPE = "CREATE_ORDER_SUCCESSFUL";
const CREATE_ORDER_FAILED_TYPE = "CREATE_ORDER_FAILED";

function actionCreateOrder(orderDetails) {
  return {
    types: [
      CREATE_ORDER_START_TYPE,
      CREATE_ORDER_SUCCESSFUL_TYPE,
      CREATE_ORDER_FAILED_TYPE,
    ],
    promise: apiInstance.post(`/order`, {
      products: orderDetails.products,
      paymentMethod: orderDetails.paymentMethod,
      address: orderDetails.address,
      discount_codes: [orderDetails.coupon],
      varietyItems: orderDetails.varietyItems,
      isReorder: orderDetails.isReorder,
    }),
  };
}

const FETCH_COUPON_START_TYPE = "FETCH_COUPON_START";
const FETCH_COUPON_SUCCESSFUL_TYPE = "FETCH_COUPON_SUCCESSFUL";
const FETCH_COUPON_FAILED_TYPE = "FETCH_COUPON_FAILED";

function actionFetchCoupon(coupon) {
  return {
    types: [
      FETCH_COUPON_START_TYPE,
      FETCH_COUPON_SUCCESSFUL_TYPE,
      FETCH_COUPON_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/discount/${coupon}`),
  };
}

const REMOVE_COUPON_TYPE = "REMOVE_COUPON";

function actionRemoveCoupon() {
  return {
    type: REMOVE_COUPON_TYPE,
  };
}

const SET_PAYMENT_METHOD_TYPE = "SET_PAYMENT_METHOD";

function actionSetPaymentMethod(paymentMethod) {
  return {
    type: SET_PAYMENT_METHOD_TYPE,
    payload: {
      paymentMethod,
    },
  };
}

export {
  actionCreateOrder,
  actionSaveAddress,
  actionSetProductCharges,
  actionUpdateAddress,
  actionLoadAddresses,
  actionFetchCoupon,
  actionRemoveCoupon,
  actionSetPaymentMethod,
  SET_PAYMENT_METHOD_TYPE,
  REMOVE_COUPON_TYPE,
  SET_PRODUCT_CHARGES_TYPE,
  SAVE_ADDRESS_START_TYPE,
  SAVE_ADDRESS_SUCCESSFUL_TYPE,
  SAVE_ADDRESS_FAILED_TYPE,
  UPDATE_ADDRESS_START_TYPE,
  UPDATE_ADDRESS_SUCCESSFUL_TYPE,
  UPDATE_ADDRESS_FAILED_TYPE,
  LOAD_ADDRESSES_START_TYPE,
  LOAD_ADDRESSES_SUCCESSFUL_TYPE,
  LOAD_ADDRESSES_FAILED_TYPE,
  FETCH_COUPON_START_TYPE,
  FETCH_COUPON_SUCCESSFUL_TYPE,
  FETCH_COUPON_FAILED_TYPE,
  CREATE_ORDER_FAILED_TYPE,
  CREATE_ORDER_SUCCESSFUL_TYPE,
};
