import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import * as Yup from "yup";

// Components
import { Button } from "../../components/inputs";

// Utilities
import { apiInstance } from "./../../config";
import AuthContext from "../../providers/auth";
import { signup } from "../../services/auth";
import { useStyles } from "./style";

const SignUpSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "Emails don't match")
    .required("Required"),
  password: Yup.string().min(8).required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  phoneExt: Yup.string(),
  address1: Yup.string().required("Required"),
  address2: Yup.string(),
  buzzerCode: Yup.string(),
  city: Yup.string().required("Required"),
  majorIntersection: Yup.string(),
  province: Yup.string(),
  postalCode: Yup.string(),
});

const postalCodeMessages = {
  invalid:
    "Sorry, we do not deliver to you. Contact info@bddeliveries about adding your region for delivery.",
  valid: "We deliver to you!",
};

function Signup() {
  const { setAuth } = useContext(AuthContext);
  const classes = useStyles();

  const [postalCodeStatus, setPostalCodeStatus] = useState(null);

  const hasValidPostalCode = postalCodeStatus === "valid";
  const postalCodeMessage = postalCodeMessages[postalCodeStatus];

  const validatePostalCode = async (value, formikBag) => {
    if (!value) return;

    const { setFieldError, setSubmitting } = formikBag;
    setSubmitting(true);

    try {
      const result = await apiInstance.get(`/address/postal_code/${value}`);
      if (!result.data.postalCode) throw new Error("Invalid postal code");

      setPostalCodeStatus("valid");
      setFieldError("postalCode", null);
    } catch (e) {
      setPostalCodeStatus("invalid");
      setFieldError("postalCode", "No delivery to postal code");
    }

    setSubmitting(false);
  };

  /**
   *
   */
  async function onSubmit(fieldValues, formikBag) {
    const { setFieldError, setSubmitting } = formikBag;

    try {
      await signup(fieldValues);
    } catch (error) {
      setSubmitting(false);

      if (error.includes("email already exists")) {
        setFieldError("email", "Email is already used");
      } else if (error.includes("not a valid postal code")) {
        setFieldError("postalCode", "Invalid postal code");
        setPostalCodeStatus("invalid");
      }
      return;
    }

    setSubmitting(false);
    setAuth({ state: "login" });
  }

  return (
    <Card className={classes.root} variant={"outlined"}>
      <CardHeader title="Create Account" />

      <Divider />

      <Formik
        enableReinitialize
        initialTouched
        initialValues={{
          email: "",
          confirmEmail: "",
          password: "",
          confirmPassword: "",
          firstName: "",
          lastName: "",
          phone: "",
          phoneExt: "",
          address1: "",
          address2: "",
          buzzerCode: "",
          city: "",
          majorIntersection: "",
          postalCode: "",
          province: "ON",
        }}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={onSubmit}
      >
        {(formikBag) => {
          const {
            errors,
            values,
            handleSubmit,
            handleChange,
            isSubmitting,
          } = formikBag;
          return (
            <React.Fragment>
              <CardContent>
                <div>
                  <div className={classes.formSectionTitle}>Check Delivery</div>
                  <div className={classes.postalCodeCheck}>
                    <TextField
                      disabled={isSubmitting}
                      error={errors.postalCode}
                      helperText={
                        errors.postalCode ||
                        "Delivery is limited by postal code"
                      }
                      label="Postal Code"
                      name="postalCode"
                      placeholder="Postal Code"
                      size="small"
                      value={values.postalCode}
                      variant="outlined"
                      onChange={handleChange}
                    />
                    <Button
                      className={classes.postalCodeCheckButton}
                      width="100px"
                      onClick={() =>
                        validatePostalCode(values.postalCode, formikBag)
                      }
                    >
                      Check
                    </Button>
                    {postalCodeStatus && (
                      <Alert
                        className={classes.postalCodeCheckMessage}
                        severity={hasValidPostalCode ? "success" : "error"}
                      >
                        {postalCodeMessage}
                      </Alert>
                    )}
                  </div>
                </div>
                <Divider className={classes.formSectionDivider} />
                <div>
                  <div className={classes.formSectionTitle}>
                    Account Details
                  </div>
                  <Grid container spacing={3}>
                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        type="email"
                        name="email"
                        placeholder="Email"
                        label="Email"
                        value={values.email}
                        error={errors.email}
                        helperText={errors.email}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        type="email"
                        name="confirmEmail"
                        placeholder="Confirm Email"
                        label="Confirm Email"
                        value={values.confirmEmail}
                        error={errors.confirmEmail}
                        helperText={errors.confirmEmail}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        type="password"
                        name="password"
                        placeholder="Password"
                        label=" Password"
                        value={values.password}
                        error={errors.password}
                        helperText={errors.password}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        error={errors.confirmPassword}
                        fullWidth
                        helperText={errors.confirmPassword}
                        label="Confirm Password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        type="password"
                        value={values.confirmPassword}
                        variant="outlined"
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="firstName"
                        placeholder="First Name"
                        label="First Name"
                        value={values.firstName}
                        error={errors.firstName}
                        helperText={errors.firstName}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="lastName"
                        placeholder="Last Name"
                        label="Last Name"
                        value={values.lastName}
                        error={errors.lastName}
                        helperText={errors.lastName}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="phone"
                        placeholder="Phone"
                        label="Phone"
                        value={values.phone}
                        error={errors.phone}
                        helperText={errors.phone}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="phoneExt"
                        placeholder="Phone Ext"
                        label="Phone Ext"
                        value={values.phoneExt}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="address1"
                        placeholder="Address 1"
                        label="Address 1"
                        value={values.address1}
                        error={errors.address1}
                        helperText={errors.address1}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="address2"
                        placeholder="Address 2"
                        label="Address 2"
                        value={values.address2}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="buzzerCode"
                        placeholder="Buzzer Code"
                        label="Buzzer Code"
                        value={values.buzzerCode}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="city"
                        placeholder="City"
                        label="City"
                        value={values.city}
                        error={errors.city}
                        helperText={errors.city}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item lg={6}>
                      <TextField
                        disabled={isSubmitting || !hasValidPostalCode}
                        fullWidth
                        variant="outlined"
                        name="majorIntersection"
                        placeholder="Major Intersection"
                        label="Major Intersection"
                        value={values.majorIntersection}
                        error={errors.majorIntersection}
                        helperText={errors.majorIntersection}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <TextField
                        disabled
                        fullWidth
                        label="Postal Code"
                        placeholder="Postal Code"
                        variant="outlined"
                        value={values.postalCode}
                      />
                    </Grid>
                  </Grid>
                </div>
              </CardContent>

              <Divider />

              <CardContent>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-end"
                  justify="flex-end"
                  spacing={1}
                >
                  <Grid item lg={2}>
                    <Button
                      disabled={isSubmitting || !hasValidPostalCode}
                      onClick={handleSubmit}
                    >
                      Sign Up
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </React.Fragment>
          );
        }}
      </Formik>
    </Card>
  );
}

export default Signup;
