import axios from "axios";

import config from "../../config";

const FETCH_PRODUCTS_START_TYPE = "FETCH_PRODUCTS_START";
const FETCH_PRODUCTS_SUCCESSFUL_TYPE = "FETCH_PRODUCTS_SUCCESSFUL";
const FETCH_PRODUCTS_FAILED_TYPE = "FETCH_PRODUCTS_FAILED";

function actionFetchProducts(company, page, perPage) {
  return {
    types: [
      FETCH_PRODUCTS_START_TYPE,
      FETCH_PRODUCTS_SUCCESSFUL_TYPE,
      FETCH_PRODUCTS_FAILED_TYPE,
    ],
    promise: axios.get(`${config.api_url}/product`, {
      params: {
        page,
        per_page: perPage,
        company,
        order_by: "product_name",
      },
    }),
  };
}

const SEARCH_PRODUCTS_START_TYPE = "SEARCH_PRODUCTS_START";
const SEARCH_PRODUCTS_SUCCESSFUL_TYPE = "SEARCH_PRODUCTS_SUCCESSFUL";
const SEARCH_PRODUCTS_FAILED_TYPE = "SEARCH_PRODUCTS_FAILED";

function actionSearchProducts(company, searchTerm, page) {
  return {
    types: [
      SEARCH_PRODUCTS_START_TYPE,
      SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
      SEARCH_PRODUCTS_FAILED_TYPE,
    ],
    promise: axios.get(`${config.api_url}/product`, {
      params: {
        page,
        per_page: 16,
        company,
        search_term: searchTerm,
        order_by: "product_name",
      },
    }),
  };
}

export {
  actionFetchProducts,
  actionSearchProducts,
  FETCH_PRODUCTS_START_TYPE,
  FETCH_PRODUCTS_SUCCESSFUL_TYPE,
  FETCH_PRODUCTS_FAILED_TYPE,
  SEARCH_PRODUCTS_START_TYPE,
  SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
  SEARCH_PRODUCTS_FAILED_TYPE,
};
