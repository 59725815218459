import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import default_beer_image from "../../default-beer-image.jpg";

function ProductCard({ product }) {
  const { product_name: productName, image_url: imageUrl, category } = product;

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Link
        to={{
          pathname: `/product/${productName}`,
        }}
        style={{ textDecoration: "none" }}
      >
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              src={imageUrl}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = default_beer_image;
              }}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h2"
                color="textSecondary"
              >
                {productName}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {category}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  );
}
// snake_case denotes data sourced directly from API response without transformation
ProductCard.propTypes = {
  product: PropTypes.shape({
    product_name: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
  }),
};

export default ProductCard;
