import styled from "styled-components";

const media = {
  xs: (styles) => `
    @media only screen and (max-width: 480px) {
      ${styles}
    }
  `,
};

export const Grid = styled.div`
  border: 5px solid red;
`;

export const Row = styled.div`
  display: flex;
  height: auto;
  flex-direction: row;
  ${() =>
    media["xs"](`
    flex-direction: column;
  `)};
  flex-wrap: wrap;
  align-items: center;
`;

export const Column = styled.div`
  flex: ${(props) => props.size};
  ${(props) =>
    props.collapse &&
    media[props.collapse](`
    display: none;
  `)};
  ${() =>
    media["xs"](`
    width: 100%;
    padding: 0px;
    margin: 0px;
  `)};
`;
