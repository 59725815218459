import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  LOAD_PAYMENTS_START_TYPE,
  LOAD_PAYMENTS_SUCCESSFUL_TYPE,
  LOAD_PAYMENTS_FAILED_TYPE,
} from "./actions";

const initialState = {
  payments: [],
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_PAYMENTS_START_TYPE: {
      return {
        ...state,
      };
    }

    case LOAD_PAYMENTS_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        payments: [...data],
      };
    }

    case LOAD_PAYMENTS_FAILED_TYPE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

const persistConfig = {
  key: "payments",
  storage,
};

export default persistReducer(persistConfig, reducer);
