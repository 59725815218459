import React from "react";
import PropTypes from "prop-types";

import { textInput, labelStyles } from "../styles/form";

import { Row, Column } from "../utils";

function TextArea(props) {
  const { name, label, onChange, value, placeholder, rows } = props;

  return (
    <Row>
      {label ? (
        <Column size={2}>
          <label htmlFor={name} style={labelStyles}>
            {label}
          </label>
        </Column>
      ) : (
        <Column size={2} collapse="xs" />
      )}
      <Column size={8}>
        <textarea
          name={name}
          style={{
            ...textInput,
            height: "auto",
            width: "100%",
          }}
          rows={rows || "5"}
          onInput={onChange}
          defaultValue={value}
          placeholder={placeholder}
        />
      </Column>
      <Column size={2} collapse="xs" />
    </Row>
  );
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
};

export default TextArea;
