import {
  GET_ORDERS_START_TYPE,
  GET_ORDERS_SUCCESSFUL_TYPE,
  GET_ORDERS_FAILED_TYPE,
  EDIT_ACCOUNT_START_TYPE,
  EDIT_ACCOUNT_FAILED_TYPE,
  EDIT_ACCOUNT_SUCCESSFUL_TYPE,
  GET_ACCOUNT_FAILED_TYPE,
  GET_ACCOUNT_SUCCESSFUL_TYPE,
  GET_ACCOUNT_START_TYPE,
} from "./actions";

const initialState = {
  orders: [],
  account: {
    email: "",
    confirmEmail: "",
    firstName: "",
    lastName: "",
    phone: "",
    phoneExt: "",
  },
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_START_TYPE: {
      return {
        isGettingAccount: true,
        ...state,
      };
    }

    case GET_ACCOUNT_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        isGettingAccount: false,
        account: {
          ...data,
          confirmEmail: data.email,
          phoneExt: data.phoneExt ? data.phoneExt : "",
        },
      };
    }

    case GET_ACCOUNT_FAILED_TYPE: {
      return {
        isGettingAccount: false,
        ...state,
      };
    }

    case EDIT_ACCOUNT_START_TYPE: {
      return {
        isEditingAccount: true,
        ...state,
      };
    }

    case EDIT_ACCOUNT_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        isEditingAccount: false,
        account: {
          ...data,
          confirmEmail: data.email,
          phoneExt: data.phoneExt ? data.phoneExt : "",
        },
        ...state,
      };
    }

    case EDIT_ACCOUNT_FAILED_TYPE: {
      return {
        isEditingAccount: false,
        ...state,
      };
    }

    case GET_ORDERS_START_TYPE: {
      return {
        isFetchingOrders: true,
        ...state,
      };
    }

    case GET_ORDERS_SUCCESSFUL_TYPE: {
      const { data } = action.payload;
      return {
        ...state,
        isFetchingOrders: false,
        orders: data,
      };
    }

    case GET_ORDERS_FAILED_TYPE: {
      return {
        isFetchingOrders: false,
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
