import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";

// Components
import { Button } from "../../components/inputs";

import { useStyles } from "./styled";

import { apiInstance } from "./../../config";
import { colors } from "../../constants";

const validPostalCodeMessage = "We deliver to you!";
const invalidPostalCodeMessage = "We don't deliver to your area.";

function PostalCodeCard() {
  const classes = useStyles();

  const [postalCodeAttempt, setPostalCodeAttempt] = useState(null);
  const [postalCodeMessageColor, setPostalCodeMessageColor] = useState("white");
  const [postalCodeMessage, setPostalCodeMessage] = useState("");

  const checkIsValidPostalCode = () => {
    apiInstance
      .get(`/address/postal_code/${postalCodeAttempt}`)
      .then((res) => {
        if (res.data.postalCode) {
          setPostalCodeMessageColor(colors.successGreen);
          setPostalCodeMessage(validPostalCodeMessage);
        } else {
          setPostalCodeMessageColor(colors.warningBackground);
          setPostalCodeMessage(invalidPostalCodeMessage);
        }
      })
      .catch(() => {
        setPostalCodeMessageColor(colors.warningBackground);
        setPostalCodeMessage(invalidPostalCodeMessage);
      });
  };
  return (
    <Card className={classes.root}>
      <CardHeader title="Check if we deliver to you" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item>
            <TextField
              placeholder="Postal Code"
              onChange={(e) => {
                setPostalCodeAttempt(e.target.value);
              }}
              name="postalCode"
              type="text"
              size="small"
              variant="outlined"
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  checkIsValidPostalCode();
                  ev.preventDefault();
                }
              }}
            />
          </Grid>
          <Grid item>
            <Button
              height="40px"
              width="100px"
              onClick={checkIsValidPostalCode}
            >
              Check
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent
        style={{
          backgroundColor: postalCodeMessageColor,
        }}
      >
        {postalCodeMessage}
      </CardContent>
    </Card>
  );
}

export default PostalCodeCard;
