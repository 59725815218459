import styled from "styled-components";

export const VerticalSpacer = styled.div`
  width: 10px;
  height: 10px;
`;

export const HorizontalSpacer = styled.div`
  width: 10px;
  display: inline-block;
`;
