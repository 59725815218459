import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  checkoutCharges: {
    textAlign: "end",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
  },
  checkoutSubTotal: {
    backgroundColor: theme.palette.grey[400],
  },
}));

export { useStyles };
