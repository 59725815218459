import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

import { RadioItemStyles, RadioItemDescriptionStyles } from "./styled";

function PaymentSection({ onClick }) {
  const selectedPaymentMethod = useSelector(
    (state) => state.checkout.paymentMethod
  );

  return (
    <>
      <RadioItemStyles>Payment Method</RadioItemStyles>
      <RadioItemStyles>
        <input
          type="radio"
          name="paymentMethod"
          value="cash"
          id="cash"
          onClick={onClick}
          checked={selectedPaymentMethod === "cash"}
        />
        <div style={{ width: "10px", display: "inline-block" }} />
        <label htmlFor="cash">Cash</label>
      </RadioItemStyles>
      <RadioItemStyles>
        <input
          type="radio"
          name="paymentMethod"
          value="interac"
          id="interac"
          onClick={onClick}
          checked={selectedPaymentMethod === "interac"}
        />
        <div style={{ width: "10px", display: "inline-block" }} />
        <label htmlFor="interac">
          Interac
          <RadioItemDescriptionStyles>
            $2 base surcharge and $2 surcharge on every $100 for Interact payments
          </RadioItemDescriptionStyles>
        </label>
      </RadioItemStyles>
      <RadioItemStyles>
        <input
          type="radio"
          name="paymentMethod"
          value="mastercard"
          id="mastercard"
          onClick={onClick}
          checked={selectedPaymentMethod === "mastercard"}
        />
        <div style={{ width: "10px", display: "inline-block" }} />
        <label htmlFor="mastercard">
          Mastercard
          <RadioItemDescriptionStyles>
            $5 surcharge on every $100 for Mastercard payments
          </RadioItemDescriptionStyles>
        </label>
      </RadioItemStyles>
      <RadioItemStyles>
        <input
          type="radio"
          name="paymentMethod"
          value="visa"
          id="visa"
          onClick={onClick}
          checked={selectedPaymentMethod === "visa"}
        />
        <div style={{ width: "10px", display: "inline-block" }} />
        <label htmlFor="visa">
          Visa
          <RadioItemDescriptionStyles>
            $5 surcharge on every $100 for Visa payments
          </RadioItemDescriptionStyles>
        </label>
      </RadioItemStyles>
      <RadioItemStyles>
        Please have your card or cash ready for the driver
      </RadioItemStyles>
    </>
  );
}

PaymentSection.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export { PaymentSection };
