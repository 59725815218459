import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  Typography,
} from "@material-ui/core";
import moment from "moment";

// Components
import { Button } from "../../components/inputs";

import { actionGetOrders } from "./actions";
import {
  actionAddProductToCart,
  actionEmptyCartItems,
  actionIsReorder,
} from "../Cart/actions";

import { WarningBoxStyles, useStyles } from "./styled";

function Reorder() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const orders = useSelector((state) => state.manage.orders);

  useEffect(() => {
    dispatch(actionGetOrders());
  }, []);

  const formatPrice = (price, quantity) => {
    return (price * quantity).toFixed(2);
  };

  const handleReorder = (order) => {
    dispatch(actionEmptyCartItems());
    const promises = [];
    dispatch(actionIsReorder());
    order.orderProducts.forEach((product) => {
      promises.push(
        dispatch(
          actionAddProductToCart(
            {
              product_name: product.productName,
              ...product,
            },
            product.quantity,
            product.notes
          )
        )
      );
    });
    Promise.all(promises).then(() => {
      window.location.href = "/cart";
    });
  };

  return (
    <Card className={classes.root} variant={"outlined"}>
      <CardHeader title="Reorder" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          {!orders.length ||
            (orders.length === 0 && (
              <WarningBoxStyles>
                {" "}
                You haven&lsquo;t made any orders!{" "}
              </WarningBoxStyles>
            ))}
        </Grid>
        <Grid container spacing={3} className={classes.root}>
          <Grid item lg={5}>
            <Typography variant="h6">Products</Typography>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6">Total</Typography>
          </Grid>
          <Grid item lg={3}>
            <Typography variant="h6">Date</Typography>
          </Grid>
          <Grid item lg={2}>
            <Typography variant="h6">Reorder</Typography>
          </Grid>
        </Grid>
        {orders.map((order) => {
          return (
            <div key={order.id} className={classes.orderContent}>
              <Grid container spacing={3} className={classes.root}>
                <Grid item lg={5}>
                  <Grid container>
                    {order.orderProducts ? (
                      order.orderProducts.map((product) => {
                        return (
                          <Grid key={product.productName} item lg={12}>
                            {product.quantity} x {product.productName}{" "}
                            {product.size} @{" "}
                            {formatPrice(product.price, product.quantity)}
                          </Grid>
                        );
                      })
                    ) : (
                      <div> No Products </div>
                    )}
                  </Grid>
                </Grid>
                <Grid item lg={2}>
                  {order.totalCharged}
                </Grid>
                <Grid item lg={3}>
                  {moment(order.receivedAt).format("DD/MM/YYYY hh:mma")}
                </Grid>
                <Grid item lg={2}>
                  <Button onClick={() => handleReorder(order)}>Reorder</Button>
                </Grid>
              </Grid>
              <Divider />
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
}

export default Reorder;
