import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";

import { useStyles } from "./styled";
import { colors } from "../../constants";

import PostalCodeCard from "./postalCodeCard";

import arrive_alive from "../../arrive_alive.png";
import banner from "../../banner.jpg";

function StepCard(props) {
  const classes = useStyles();
  return (
    <Card className={classes.stepCard} style={{ color: props.color }}>
      <CardContent className={classes.stepCardContent}>
        {props.number ? (
          <span className={classes.stepCardNumber}>{props.number}</span>
        ) : (
          ""
        )}
        <Typography className={classes.stepCardText}>
          {props.content}
        </Typography>
      </CardContent>
    </Card>
  );
}

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item lg={7}>
          <Typography variant="h4" component="h4">
            Welcome to B&D Deliveries
          </Typography>
          <Typography className={classes.paddedItem}>
            B & D Deliveries has proudly served our customers in Kitchener,
            Waterloo, Cambridge and Guelph since 1987.
          </Typography>
          <img alt="Arrive Alive Logo" src={arrive_alive} className={classes.imageBlock} />
          <a
            className={classes.imageText}
            href="http://www.arrivealive.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Proud Supporter of Arrive Alive
          </a>
        </Grid>
        <Grid item lg={5}>
          <PostalCodeCard />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={2}>
          <StepCard content="HOW IT WORKS!" color={colors.redText} />
        </Grid>
        <Grid item lg={2}>
          <StepCard content="Sign up with your address" number="1" />
        </Grid>
        <Grid item lg={3}>
          <StepCard
            content="Pick your items from The Beer Store, LCBO, or both!"
            number="2"
          />
        </Grid>
        <Grid item lg={2}>
          <StepCard content="Submit your order" number="3" />
        </Grid>
        <Grid item lg={3}>
          <StepCard content="Pay by cash or card on delivery" number="4" />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Card className={classes.imageCard}>
            <img alt="Summary" src={banner} style={{ width: "100%", height: "100%" }} />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;
