import axios from "axios";

import config, { apiInstance } from "../config";

export const login = async (email, password) => {
  let response;
  try {
    response = await axios.post(`${config.api_url}/account/login`, {
      email,
      password,
    });
  } catch (error) {
    return Promise.reject(error.response.data.message);
  }

  if (response) {
    apiInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${response.data.token}`;
    localStorage.setItem("x-access-token", response.data.token);
    localStorage.setItem(
      "x-access-token-expiration",
      Date.now() + config.tokenTimeout
    );
  }

  return { state: "login" };
};

export const signup = async (userInformation) => {
  const { email, password } = userInformation;

  try {
    await apiInstance.post(`${config.api_url}/account/signup`, {
      ...userInformation,
    });
  } catch (error) {
    return Promise.reject(error.response.data.message);
  }

  try {
    await login(email, password);
    window.location.href = "/";
  } catch (error) {
    return Promise.reject(error);
  }
  return;
};

export const reset = async (userInformation) => {
  const { newPassword, currentPassword, token, email } = userInformation;

  try {
    await apiInstance.post(`${config.api_url}/account/reset-password`, {
      new_password: newPassword,
      current_password: currentPassword,
      token,
      email,
    });
  } catch (error) {
    return Promise.reject(error);
  }

  try {
    await login(email, newPassword);
    window.location.href = "/";
  } catch (error) {
    return Promise.reject(error);
  }
};

export const logout = () => {
  localStorage.removeItem("persist:cartItems");
  localStorage.removeItem("persist:checkout");
  localStorage.removeItem("persist:payments");
  localStorage.removeItem("x-access-token");
  localStorage.removeItem("x-access-token-expiration");
  return { state: "logout" };
};

export const isAuthenticated = () => {
  const is_authed =
    localStorage.getItem("x-access-token") &&
    localStorage.getItem("x-access-token-expiration") > Date.now();

  if (!is_authed) {
    localStorage.removeItem("persist:cartItems");
    localStorage.removeItem("persist:checkout");
    localStorage.removeItem("x-access-token");
    localStorage.removeItem("x-access-token-expiration");
  }

  return is_authed;
};
