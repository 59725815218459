import { Container, Grid, Hidden, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "../../components/inputs";
import { HorizontalSpacer, VerticalSpacer } from "../../components/utils";
import AuthContext from "../../providers/auth";
import { logout } from "../../services/auth";
import { actionLogout } from "./../../store/actions";
import Navbar from "./Navbar";
import {
  ActionText,
  BoldText,
  CenterTextStyles,
  CouponAlertStyleWrapper,
  ForceRight,
  HoverText,
  RedText,
  UserActionWrapper,
} from "./styled";
import { actionLoadPaymentTypes } from "./../../store/actions";

// eslint-disable-next-line no-undef
const logo = require("../../logo.png");

const MenuBarText = (props) => {
  return (
    <ActionText>
      <HoverText>
        <BoldText>{props.children}</BoldText>
      </HoverText>
    </ActionText>
  );
};

MenuBarText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Nav({ openAuthModal }) {
  const dispatch = useDispatch();
  const { auth, setAuth } = useContext(AuthContext);

  dispatch(actionLoadPaymentTypes());

  const selectedCartItemsLength = Object.values(
    useSelector((state) => state.cart.selectedCartItems)
  ).length;

  const onLogout = () => {
    logout();
    dispatch(actionLogout());

    setAuth({ state: "logout" });

    window.location.href = "/";
  };

  // enhancement candidate: go to cart after login
  const goToCart = () => {
    if (auth.state === "login") {
      window.location.href = "/cart";
    } else {
      openAuthModal();
    }
  };

  const goToSignup = () => (window.location.href = "/signup");

  const goToAccount = () => (window.location.href = "/account");
  const goToReorder = () => (window.location.href = "/reorder");
  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item lg={3} md={5} sm={7} xs={12}>
            <Link
              to={{
                pathname: "/",
              }}
            >
              <img src={logo} alt="BD Deliveries logo" width={350} />
            </Link>

            <CouponAlertStyleWrapper>
              <RedText>SAVE:</RedText> Use coupon &quot;bdonline&quot; and save
              $0.50 off delivery charges
            </CouponAlertStyleWrapper>
          </Grid>

          <Grid
            item
            lg={6}
            md={4}
            sm={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Hidden xsDown implementation="css">
              <CenterTextStyles>
                Kitchener - Waterloo 519-746-4910
                <br />
                Cambridge - Guelph 519-654-2437
                <br />
                <RedText>
                  Don&apos;t Drink and Drive - call B&D instead!
                </RedText>
                <br />
                Licensed by the AGCO
                <br />
                Feel free to contact us at&nbsp;
                <ActionText> info@bddeliveries.ca </ActionText>
              </CenterTextStyles>
            </Hidden>
          </Grid>

          <Grid
            item
            lg={3}
            md={3}
            sm={6}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <UserActionWrapper>
              {auth.state === "login" ? (
                <div>
                  <ActionText onClick={goToReorder}> Reorder </ActionText>
                  <ActionText onClick={goToAccount}> Account </ActionText>
                  <ActionText onClick={onLogout}> Logout </ActionText>
                </div>
              ) : (
                <div>
                  <ActionText onClick={openAuthModal}> Login </ActionText>
                  <HorizontalSpacer />
                  <ActionText onClick={goToSignup}> Sign Up </ActionText>
                </div>
              )}
              <VerticalSpacer />
              <ForceRight>
                <Button onClick={goToCart}>
                  Your Cart ({selectedCartItemsLength} items)
                </Button>
              </ForceRight>
              <VerticalSpacer />
              <Typography variant="caption">
                For your rush courier needs please call
                <br />
                B&D Courier Express at 519-746-4910
              </Typography>
            </UserActionWrapper>
          </Grid>
        </Grid>
      </Container>

      <Navbar />
    </React.Fragment>
  );
}

Nav.propTypes = {
  openAuthModal: PropTypes.func.isRequired,
};

export default Nav;
