import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Grid } from "@material-ui/core";

import AuthContext from "../../providers/auth";

import { useStyles, WarningBoxStyles } from "./styled";

import { actionFetchReviews, actionSubmitReview } from "./actions";

import ReviewsSection from "./ReviewsSection";
import ReviewAdd from "./ReviewAdd";

function Reviews() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { auth } = useContext(AuthContext);

  useEffect(() => {
    dispatch(actionFetchReviews());
  }, [dispatch]);

  const submitReview = (reviewData) => {
    dispatch(actionSubmitReview(reviewData));
  };

  const reviewsData = useSelector((state) => state.reviews.data);

  const isFetchingReviews = useSelector(
    (state) => state.reviews.isFetchingReviews
  );

  if (isFetchingReviews) {
    return (
      <Grid container spacing={5} className={classes.root} justify="center">
        <Grid item>
          <CircularProgress color="primary" disableShrink />
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      {auth.state === "login" ? (
        <ReviewAdd submitReview={submitReview} />
      ) : (
        <WarningBoxStyles>Sign in to leave a review!</WarningBoxStyles>
      )}
      <ReviewsSection
        reviewsData={reviewsData}
        isFetchingReviews={isFetchingReviews}
      />
    </div>
  );
}

export default Reviews;
