import {
  FETCH_PRODUCTS_START_TYPE,
  FETCH_PRODUCTS_SUCCESSFUL_TYPE,
  FETCH_PRODUCTS_FAILED_TYPE,
  SEARCH_PRODUCTS_START_TYPE,
  SEARCH_PRODUCTS_SUCCESSFUL_TYPE,
  SEARCH_PRODUCTS_FAILED_TYPE,
} from "./actions";

const initialState = {
  data: [],
  isFetchingProducts: false,

  searchedData: [],
  isSearchingProducts: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_START_TYPE: {
      return {
        ...state,
        isFetchingProducts: true,
      };
    }

    case FETCH_PRODUCTS_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        isFetchingProducts: false,
        data: [...data.products],
        total: data.total,
      };
    }

    case FETCH_PRODUCTS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingProducts: false,
      };
    }

    case SEARCH_PRODUCTS_START_TYPE: {
      return {
        ...state,
        isSearchingProducts: true,
      };
    }

    case SEARCH_PRODUCTS_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        isSearchingProducts: false,
        searchedData: [...data.products],
        total: data.total,
      };
    }

    case SEARCH_PRODUCTS_FAILED_TYPE: {
      return {
        ...state,
        isSearchingProducts: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
