import { CircularProgress, Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import isEmpty from "lodash.isempty";
import qs from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionFetchProducts, actionSearchProducts } from "./actions";
import ProductCard from "./ProductCard";
import { useStyles } from "./styled";

function Products({ history, location }) {
  const { search } = location;

  const dispatch = useDispatch();
  const classes = useStyles();

  let searchTerm, company;

  if (search) {
    const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
    searchTerm = parsedSearch.searchTerm;
    company = parsedSearch.company;
  }

  const [pageNumber, setPageNumber] = useState(1);
  const [perPage] = useState(16);

  useEffect(() => {
    dispatch(actionFetchProducts(company, pageNumber, perPage));
  }, [dispatch, company, pageNumber, perPage]);

  useEffect(() => {
    if (searchTerm) {
      dispatch(actionSearchProducts(company, searchTerm));
    }
  }, [dispatch, company, searchTerm]);

  const productsData = useSelector((state) => state.products.data);
  const totalPages = useSelector((state) => state.products.total);

  const isFetchingProducts = useSelector(
    (state) => state.products.isFetchingProducts
  );

  const searchedProductsData = useSelector(
    (state) => state.products.searchedData
  );
  const isSearchingProducts = useSelector(
    (state) => state.products.isSearchingProducts
  );

  /**
   * // TODO: Figure out why this is needed
    const uniqueProducts = Array.from(
      new Set(products.data.map((a) => a.product_name))
    ).map((product_name) =>
      products.data.find((a) => a.product_name === product_name)
    );
   */

  if (isFetchingProducts || isSearchingProducts) {
    return (
      <Grid container spacing={5} className={classes.root} justify="center">
        <Grid item>
          <CircularProgress color="primary" disableShrink />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        {!isEmpty(searchTerm) && !isEmpty(searchedProductsData)
          ? searchedProductsData.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))
          : productsData.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
      </Grid>

      <Grid container spacing={3} className={classes.root}>
        <Pagination
          page={pageNumber}
          onChange={(e, page) => setPageNumber(page)}
          count={totalPages ? totalPages : 1}
        />
      </Grid>
    </>
  );
}

export default Products;
