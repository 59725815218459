import { apiInstance } from "./../config";

const LOGOUT_TYPE = "LOGOUT";

function actionLogout() {
  return {
    type: LOGOUT_TYPE,
  };
}

const LOAD_PAYMENTS_START_TYPE = "LOAD_PAYMENTS_START";
const LOAD_PAYMENTS_SUCCESSFUL_TYPE = "LOAD_PAYMENTS_SUCCESSFUL";
const LOAD_PAYMENTS_FAILED_TYPE = "LOAD_PAYMENTS_FAILED";

function actionLoadPaymentTypes() {
  return {
    types: [
      LOAD_PAYMENTS_START_TYPE,
      LOAD_PAYMENTS_SUCCESSFUL_TYPE,
      LOAD_PAYMENTS_FAILED_TYPE,
    ],
    promise: apiInstance.get("/payment-methods"),
  };
}

export {
  actionLogout,
  actionLoadPaymentTypes,
  LOAD_PAYMENTS_START_TYPE,
  LOAD_PAYMENTS_SUCCESSFUL_TYPE,
  LOAD_PAYMENTS_FAILED_TYPE,
  LOGOUT_TYPE,
};
