import styled from "styled-components";
import { colors } from "../../constants";

export const textInput = {
  padding: "5px",
  border: "2px solid #ccc",
  borderRadius: "4px",
  height: "24px",
  WebkitBorderRadius: "4px",
  ":focus": {
    "border-color": "#333",
  },
  width: "150px",
  verticalAlign: "middle",
};

export const labelStyles = {
  height: "24px",
  fontWeight: "bold",
};

export const FormWrapper = styled.div`
  border: 1px solid ${colors.borderGrey};
  border-radius: "5px";
`;

export const FormContentStyleWrapper = styled.div`
  padding: 15px;
`;

export const FormHeader = styled.div`
  border-bottom: 1px solid ${colors.borderGrey};
  background: ${colors.lightGrey};
  padding: 15px;
`;

export const FormFooter = styled.div`
  border-top: 1px solid ${colors.borderGrey};
  background: ${colors.lightGrey};
  padding: 15px;
  text-align: right;
`;
