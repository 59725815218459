import React from "react";
import PropTypes from "prop-types";

// Components
import { TextBox, Button } from "../../components/inputs";
import { ModalHeader, ModalFooter } from "../styles/modal";

// Styles
import { ModalActionsWrapper, ModalFormBackLink } from "./styled";

function ForgotPassword(props) {
  const {
    userInformationState,
    checkEmail,
    toggleForgot,
    submitEmail,
    handleChange,
  } = props;
  return (
    <div>
      <ModalHeader> Login </ModalHeader>
      <form>
        <TextBox
          type="email"
          name="email"
          label="Email:"
          value={userInformationState.email}
          onChange={handleChange}
          required
        />
        <ModalFormBackLink onClick={toggleForgot}>Back to Login</ModalFormBackLink>
        <ModalFooter>
          {checkEmail ? <div> Check your email! </div> : <div />}
          <ModalActionsWrapper>
            <Button
              width="200px"
              onClick={submitEmail}
            >
              Send
            </Button>
          </ModalActionsWrapper>
        </ModalFooter>
      </form>
    </div>
  );
}

ForgotPassword.propTypes = {
  userInformationState: PropTypes.shape({
    email: PropTypes.string,
  }),
  submitEmail: PropTypes.func.isRequired,
  checkEmail: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  toggleForgot: PropTypes.func.isRequired
};

export default ForgotPassword;