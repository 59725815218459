import { combineReducers } from "redux";

import CartReducer from "../pages/Cart/reducer";
import CheckoutReducer from "./../pages/Checkout/reducer";
import ProductsReducer from "./../pages/Products/reducer";
import PaymentsReducer from "./paymentsReducer";
import ReviewsReducer from "../pages/Reviews/reducer";
import HoursReducer from "../pages/Nav/reducer";
import PostalCodesReducer from "./PostalCodes/reducer";
import ManageReducer from "./../pages/Account/reducer";

const rootReducer = combineReducers({
  cart: CartReducer,
  checkout: CheckoutReducer,
  payments: PaymentsReducer,
  products: ProductsReducer,
  reviews: ReviewsReducer,
  hours: HoursReducer,
  postalCodes: PostalCodesReducer,
  manage: ManageReducer,
});

export default rootReducer;
