import React, { useContext } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";

// Components
import { Button } from "../../components/inputs";

import { useStyles } from "./styled";
import AuthContext from "../../providers/auth";
import { reset } from "../../services/auth";

const SignUpSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "Emails don't match")
    .required("Required"),
  currentPassword: Yup.string().min(8).required("Required"),
  newPassword: Yup.string().min(8).required("Required"),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords don't match")
    .required("Required"),
});

function Password() {
  const { setAuth } = useContext(AuthContext);
  const classes = useStyles();
  async function onSubmit(fieldValues) {
    try {
      await reset(fieldValues);
    } catch (error) {
      return;
    }

    setAuth({ state: "login" });
  }

  return (
    <Card className={classes.root} variant={"outlined"}>
      <CardHeader title="Reset Password" />

      <Divider />

      <Formik
        enableReinitialize
        initialTouched
        initialValues={{
          email: "",
          confirmEmail: "",
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values, handleSubmit, handleChange }) => {
          return (
            <React.Fragment>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="email"
                      name="email"
                      placeholder="Email"
                      label="Email"
                      value={values.email}
                      error={errors.email}
                      helperText={errors.email}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="email"
                      name="confirmEmail"
                      placeholder="Confirm Email"
                      label="Confirm Email"
                      value={values.confirmEmail}
                      error={errors.confirmEmail}
                      helperText={errors.confirmEmail}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="password"
                      name="currentPassword"
                      placeholder="Current Password"
                      label="Current Password"
                      value={values.password}
                      error={errors.password}
                      helperText={errors.password}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="password"
                      name="newPassword"
                      placeholder="New Password"
                      label="New Password"
                      value={values.password}
                      error={errors.password}
                      helperText={errors.password}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="password"
                      name="confirmNewPassword"
                      placeholder="Confirm New Password"
                      label="Confirm New Password"
                      value={values.password}
                      error={errors.password}
                      helperText={errors.password}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />

              <CardContent>
                <Grid
                  container
                  direction="column"
                  alignItems="flex-end"
                  justify="flex-end"
                  spacing={1}
                >
                  <Grid item lg={2}>
                    <Button onClick={handleSubmit}>Reset Password</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </React.Fragment>
          );
        }}
      </Formik>
    </Card>
  );
}

export default Password;
