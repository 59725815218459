import React, { createContext, useState } from "react";
import PropTypes from "prop-types";
import { isAuthenticated } from "../services/auth";

const AuthContext = createContext(null);

const { Provider } = AuthContext;

const initAuth = {
  state: isAuthenticated() ? "login" : "logout",
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(initAuth);

  return <Provider value={{ auth, setAuth }}>{children}</Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthContext;
