import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";

import { actionAddProductToCart } from "./actions";
import { Button, Incrementer, TextArea } from "../../components/inputs";
import { VerticalSpacer } from "../../components/utils";
import {
  ModalExit,
  ModalFooter,
  ModalHeader,
  modalPosition,
} from "../../components/styles/modal";

const INIT_ADD_TO_CART_STATE = Object.freeze({
  id: "",
  quantity: 1,
  notes: "",
});

function AddToCartModal(props) {
  const dispatch = useDispatch();
  const [addToCartItem, setAddToCartItem] = useState(INIT_ADD_TO_CART_STATE);

  const { isOpen, closeModal, setSnackbarMessage, product } = props;
  const { id: productID, product_name: productName, price, size } = product;

  useEffect(() => {
    setAddToCartItem((addToCartItem) => ({
      ...addToCartItem,
      id: productID,
    }));
  }, [productID]);

  const submitToCart = () => {
    dispatch(
      actionAddProductToCart(
        product,
        addToCartItem.quantity,
        addToCartItem.notes
      )
    );

    setSnackbarMessage(`${addToCartItem.name} added to cart`);
    closeModalAndClearCartItem();
  };

  const closeModalAndClearCartItem = () => {
    closeModal();
    setAddToCartItem(INIT_ADD_TO_CART_STATE);
  };

  const onIncrementQuantity = (increment) => {
    let newQuantity = addToCartItem.quantity + increment;
    if (newQuantity < 0) newQuantity = 0;

    setAddToCartItem((item) => ({
      ...item,
      quantity: newQuantity,
    }));
  };

  const onChangeQuantity = ({ target }) => {
    let newQuantity = parseInt(target.value, 10);
    if (isNaN(newQuantity)) newQuantity = 0;

    setAddToCartItem((item) => ({
      ...item,
      quantity: newQuantity,
    }));
  };

  const onNotesChange = ({ target }) => {
    setAddToCartItem((item) => ({
      ...item,
      notes: target.value,
    }));
  };

  // block submit button if not authed, show login/signup button
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModalAndClearCartItem}
      ariaHideApp={false}
      style={modalPosition}
    >
      <ModalExit onClick={closeModalAndClearCartItem}>X</ModalExit>
      <ModalHeader>Add To Cart</ModalHeader>
      <div>Item: {productName}</div>
      <div>Size: {size}</div>
      <div>Price: {price}</div>
      <VerticalSpacer />
      <form onSubmit={(e) => e.preventDefault()}>
        <Incrementer
          name="quantity"
          label="Quantity:"
          onClick={onIncrementQuantity}
          onChange={onChangeQuantity}
          value={addToCartItem.quantity}
        />
        <VerticalSpacer />
        <TextArea name="notes" label="Notes:" onChange={onNotesChange} />
        <ModalFooter>
          <Button
            width="200px"
            onClick={submitToCart}
          >
            Add to Cart
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}

AddToCartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  setSnackbarMessage: PropTypes.func.isRequired,
  product: PropTypes.shape({
    product_name: PropTypes.string,
    price: PropTypes.string,
    size: PropTypes.string,
  }),
};

export default AddToCartModal;
