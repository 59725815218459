import PropTypes from "prop-types";
import React, { useContext } from "react";
import {
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@material-ui/core";

import { Button } from "../../components/inputs";
import default_beer_image from "../../default-beer-image.jpg";
import AuthContext from "../../providers/auth";
import { useStyles } from "./styled";

function ProductDetailCard({
  openAddToCartModal,
  openAuthModal,
  setAddToCartProduct,
  product,
}) {
  const { auth } = useContext(AuthContext);
  const classes = useStyles();

  const {
    price,
    // abv,
    // type,
    size,
    product_name: productName,
    image_url: imageUrl,
  } = product;

  const onClick = () => {
    if (auth.state === "login") {
      setAddToCartProduct(product);
      openAddToCartModal();
    } else {
      setAddToCartProduct(product);
      openAuthModal();
    }
  };

  return (
    <Grid item lg={3} md={3} sm={6} xs={12} className={classes.root}>
      <Card
        onClick={onClick}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            src={imageUrl}
            alt={`${productName} ${size}`}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = default_beer_image;
            }}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              color="textSecondary"
            >
              {productName}
            </Typography>

            <Typography variant="body1" color="textSecondary" component="p">
              {size}
            </Typography>

            <Typography variant="body1" color="textSecondary" component="p">
              {price}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions style={{ justifyContent: "center", padding: 15 }}>
          <Button width="75%" onClick={onClick}>
            Add to cart
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

ProductDetailCard.propTypes = {
  product: PropTypes.shape({
    price: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    image_url: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
  }),
  openAuthModal: PropTypes.func.isRequired,
  openAddToCartModal: PropTypes.func.isRequired,
  setAddToCartProduct: PropTypes.func.isRequired,
};

export { ProductDetailCard };
