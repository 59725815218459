import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@material-ui/core";
import { Formik } from "formik";
import { useStyles } from "./styled";
import * as Yup from "yup";

// Components
import { Button } from "../../components/inputs";

import { actionGetAccount, actionEditAccount } from "./actions";

const SignUpSchema = Yup.object().shape({
  email: Yup.string().required("Required"),
  confirmEmail: Yup.string()
    .oneOf([Yup.ref("email"), null], "Emails don't match")
    .required("Required"),
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  phone: Yup.string().required("Required"),
  phoneExt: Yup.string(),
});

function Account() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const account = useSelector((state) => state.manage.account);

  useEffect(() => {
    dispatch(actionGetAccount());
  }, []);

  const onSubmit = async (fieldValues) => {
    dispatch(actionEditAccount({ ...fieldValues, id: account.id }));
  };

  return (
    <Card className={classes.root} variant={"outlined"}>
      <CardHeader title="Update Account" />

      <Divider />

      <Formik
        enableReinitialize
        initialTouched
        initialValues={account}
        validateOnChange={false}
        validationSchema={SignUpSchema}
        onSubmit={onSubmit}
      >
        {({ errors, values, handleSubmit, handleChange }) => {
          return (
            <React.Fragment>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="email"
                      name="email"
                      placeholder="Email"
                      label="Email"
                      value={values.email}
                      error={errors.email}
                      helperText={errors.email}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="email"
                      name="confirmEmail"
                      placeholder="Confirm Email"
                      label="Confirm Email"
                      value={values.confirmEmail}
                      error={errors.confirmEmail}
                      helperText={errors.confirmEmail}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="firstName"
                      placeholder="First Name"
                      label="First Name"
                      value={values.firstName}
                      error={errors.firstName}
                      helperText={errors.firstName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="lastName"
                      placeholder="Last Name"
                      label="Last Name"
                      value={values.lastName}
                      error={errors.lastName}
                      helperText={errors.lastName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="phone"
                      placeholder="Phone"
                      label="Phone"
                      value={values.phone}
                      error={errors.phone}
                      helperText={errors.phone}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item lg={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="phoneExt"
                      placeholder="Phone Ext"
                      label="Phone Ext"
                      value={values.phoneExt}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />

              <CardContent>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-end"
                  justify="flex-end"
                  spacing={1}
                >
                  <Button width="150px" onClick={handleSubmit}>Update</Button>
                  <Button
                    style={{ marginLeft: 16 }}
                    width="150px"
                    onClick={() => (window.location.href = "/reset-password")}
                  >
                    Reset Password
                  </Button>
                </Grid>
              </CardContent>
            </React.Fragment>
          );
        }}
      </Formik>
    </Card>
  );
}

export default Account;
