import { makeStyles } from "@material-ui/core/styles";
import { colors } from "../../constants";
import styled from "styled-components";

const WarningBoxStyles = styled.div`
  color: ${colors.warningText};
  background-color: ${colors.warningBackground};
  border: 1px solid ${colors.warningBorder};
  padding: 15px;
  margin-top: 16px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  orderContent: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export { useStyles, WarningBoxStyles };
