import axios from "axios";
import applyCaseConverters from "axios-case-converter";

export const apiInstance = applyCaseConverters(
  axios.create({
    // eslint-disable-next-line
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("x-access-token")}`,
    },
  })
);

export default {
  // eslint-disable-next-line
  api_url: process.env.REACT_APP_API_URL,
  // api_url: "https://bnd-deliveries-api.herokuapp.com",
  tokenTimeout: 12 * 60 * 60 * 1000, // 12 hours
};
