export const colors = {
  redText: "#d60000",
  greyBox: "#494949",
  orange: "#ce8328",
  lightGrey: "#f5f5f5",
  mediumGrey: "#e9e9e9",
  darkGreyText: "#4b4949",
  borderGrey: "#ddd",
  warningBackground: "#fcf8e3",
  warningText: "#8a6d3b",
  warningBorder: "#faebcc",
  successGreen: "#BCED91",
};
