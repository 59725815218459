import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { textInput, labelStyles } from "../styles/form";
import { Row, Column } from "../utils";

const TextBoxStyleWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

function TextBox(props) {
  const {
    onChange,
    name,
    value,
    label,
    type,
    required,
    placeholder,
    width,
  } = props;

  return (
    <Row>
      <Column size={2}>
        <label htmlFor={name} style={labelStyles}>
          {label}
          {required ? "*" : ""}
        </label>
      </Column>
      <Column size={8}>
        <TextBoxStyleWrapper>
          <input
            name={name}
            style={{
              ...textInput,
              width,
            }}
            onChange={onChange}
            value={value}
            type={type}
            required={required}
            placeholder={placeholder}
          />
        </TextBoxStyleWrapper>
      </Column>
      <Column size={2} collase="xs" />
    </Row>
  );
}

TextBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  width: PropTypes.string,
};

export default TextBox;
