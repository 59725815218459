import styled from "styled-components";

// Wrapper for modal action buttons
const ModalActionsWrapper = styled.div`
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
`;

// Link back to the previous form
const ModalFormBackLink = styled.div`
  margin-top: 16px;
  cursor: pointer;
`;

export { ModalActionsWrapper, ModalFormBackLink };
