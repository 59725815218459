import {
  FETCH_REVIEWS_START_TYPE,
  FETCH_REVIEWS_SUCCESSFUL_TYPE,
  FETCH_REVIEWS_FAILED_TYPE,
  SUBMIT_REVIEW_START_TYPE,
  SUBMIT_REVIEW_SUCCESSFUL_TYPE,
  SUBMIT_REVIEW_FAILED_TYPE,
} from "./actions";

const initialState = {
  data: [],
  isFetchingReviews: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REVIEWS_START_TYPE: {
      return {
        ...state,
        isFetchingReviews: true,
      };
    }

    case FETCH_REVIEWS_SUCCESSFUL_TYPE: {
      const { data } = action.payload;

      return {
        ...state,
        isFetchingReviews: false,
        data: [...state.data, ...data],
      };
    }

    case FETCH_REVIEWS_FAILED_TYPE: {
      return {
        ...state,
        isFetchingReviews: false,
      };
    }

    case SUBMIT_REVIEW_START_TYPE: {
      return {
        ...state,
        isSubmittingReview: true,
      };
    }

    case SUBMIT_REVIEW_SUCCESSFUL_TYPE: {
      const { data } = action.payload;
      return {
        ...state,
        isSubmittingReview: false,
        data: [data, ...state.data],
      };
    }

    case SUBMIT_REVIEW_FAILED_TYPE: {
      return {
        ...state,
        isSubmittingReview: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
