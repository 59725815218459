import React from "react";
import PropTypes from "prop-types";

// Components
import { TextBox, Button } from "../../components/inputs";
import { VerticalSpacer, HorizontalSpacer } from "../utils";

// Styles
import { ModalHeader, ModalFooter } from "../styles/modal";
import { ModalActionsWrapper, ModalFormBackLink } from "./styled";

function Login(props) {
  const {
    userInformationState,
    handleChange,
    submitLogin,
    clearAndClose,
  } = props;

  const onClick = () => {
    window.location.href = "/signup";
    clearAndClose();
    return;
  };

  return (
    <div>
      <ModalHeader> Login </ModalHeader>
      <form onSubmit={submitLogin} method="POST">
        <TextBox
          type="email"
          name="email"
          label="Email:"
          value={userInformationState.email}
          onChange={handleChange}
          required
        />
        <VerticalSpacer />
        <TextBox
          type="password"
          name="password"
          label="Password:"
          value={userInformationState.password}
          onChange={handleChange}
          required
        />
        <ModalFormBackLink onClick={props.toggleForgot}>
          Forgot Password?
        </ModalFormBackLink>
        <ModalFooter>
          <ModalActionsWrapper>
            <Button
              type="submit"
              width="200px"
              onClick={submitLogin}
            >
              Login
            </Button>
            <HorizontalSpacer />
            <Button
              variant="outlined"
              width="200px"
              onClick={onClick}
            >
              Create an account
            </Button>
          </ModalActionsWrapper>
        </ModalFooter>
      </form>
    </div>
  );
}

Login.propTypes = {
  userInformationState: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  submitLogin: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  clearAndClose: PropTypes.func.isRequired,
  toggleForgot: PropTypes.func.isRequired,
};

export default Login;
