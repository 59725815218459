import { apiInstance } from "../../config";

const FETCH_POSTALCODES_START_TYPE = "FETCH_POSTALCODES_START";
const FETCH_POSTALCODES_SUCCESSFUL_TYPE = "FETCH_POSTALCODES_SUCCESSFUL";
const FETCH_POSTALCODES_FAILED_TYPE = "FETCH_POSTALCODES_FAILED";

/**
 *
 */
function actionFetchPostalCodes() {
  return {
    types: [
      FETCH_POSTALCODES_START_TYPE,
      FETCH_POSTALCODES_SUCCESSFUL_TYPE,
      FETCH_POSTALCODES_FAILED_TYPE,
    ],
    promise: apiInstance.get(`/address/postal_code`),
  };
}

export {
  actionFetchPostalCodes,
  FETCH_POSTALCODES_START_TYPE,
  FETCH_POSTALCODES_SUCCESSFUL_TYPE,
  FETCH_POSTALCODES_FAILED_TYPE,
};
