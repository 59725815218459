import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

import { colors } from "../../constants";

const CardStyleWrapper = styled.div`
  background: ${colors.greyBox};
  height: auto;
  padding: 10%;
  margin: 20px 10px 20px 10px;
`;

const CardLinkWrapper = styled.div`
  color: white;
  font-weight: bold;
  margin: 5px;
  &:hover {
    color: ${colors.redText};
    text-decoration: underline;
  }
`;

const CardTextWrapper = styled.div`
  color: white;
  font-weight: bold;
  margin: 5px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

const ProductStyleWrapper = styled.div`
  margin: 25px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

export {
  CardLinkWrapper,
  CardStyleWrapper,
  CardTextWrapper,
  ProductStyleWrapper,
  RowWrapper,
  useStyles,
};
