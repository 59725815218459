import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import qs from "qs";
import moment from "moment";
import {
  AppBar,
  Box,
  InputBase,
  Toolbar,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Hidden,
  Drawer,
  IconButton,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  Menu as MenuIcon,
  Schedule as ClockIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";

// Utilities
import { actionFetchHours } from "./actions";
import { getStoreHoursMessage } from "./helpers";

// Styles
import { OpenIndicator, useStyles } from "./styled";

function NavbarItems() {
  const classes = useStyles();

  return (
    <List component="nav">
      <ListItem component="div" disableGutters>
        <ListItemText className={classes.listItemText}>
          <Link
            to={{
              pathname: "/products",
              search: "?company=lcbo",
            }}
            className={classes.listItemTextLink}
          >
            <Typography varient="h6">LCBO</Typography>
          </Link>
        </ListItemText>
        <ListItemText className={classes.listItemText}>
          <Link
            to={{
              pathname: "/products",
              search: "?company=the-beer-store",
            }}
            className={classes.listItemTextLink}
          >
            <Typography varient="h6">THE BEER STORE</Typography>
          </Link>
        </ListItemText>
        <ListItemText
          className={`${classes.listItemText} ${classes.listItemTextLink}`}
        >
          <Link
            to={{
              pathname: "/reviews",
            }}
            className={classes.listItemTextLink}
          >
            <Typography varient="h6">REVIEWS</Typography>
          </Link>
        </ListItemText>
      </ListItem>
    </List>
  );
}

function NavbarDrawerItems({ onItemTap }) {
  const classes = useStyles();

  return (
    <List component="nav">
      <ListItem component="div" disableGutters>
        <ListItemText className={classes.drawerListItemText}>
          <Link
            to={{
              pathname: "/products?company=lcbo",
            }}
            className={classes.listItemTextLink}
            onClick={onItemTap}
          >
            <Typography varient="body1">LCBO</Typography>
          </Link>
        </ListItemText>
      </ListItem>
      <ListItem component="div" disableGutters>
        <ListItemText className={classes.drawerListItemText}>
          <Link
            to={{
              pathname: "/products?company=the-beer-store",
            }}
            className={classes.listItemTextLink}
            onClick={onItemTap}
          >
            <Typography varient="body1">THE BEER STORE</Typography>
          </Link>
        </ListItemText>
      </ListItem>

      <ListItem component="div" disableGutters>
        <ListItemText
          className={`${classes.drawerListItemText} ${classes.listItemTextLink}`}
        >
          <Link
            to={{
              pathname: "/reviews",
            }}
            className={classes.listItemTextLink}
            onClick={onItemTap}
          >
            <Typography varient="body1"> REVIEWS </Typography>
          </Link>
        </ListItemText>
      </ListItem>
    </List>
  );
}

function Navbar({ location, history }) {
  const classes = useStyles();
  const [isOpenOnMobile, setOpenOnMobile] = useState(false);
  const { search } = location;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionFetchHours());
  }, [dispatch]);

  const isOpen = useSelector((state) => state.hours.isOpen);
  const closedAllDay = useSelector((state) => state.hours.closedAllDay);

  // Message about daily hours or the hours for next open day (if closed)
  const hoursMessage = useSelector((state) => getStoreHoursMessage(state));
  const closedMessage = closedAllDay ? "Closed today" : "Closed";

  const handleDrawerToggle = () => setOpenOnMobile(!isOpenOnMobile);

  const debounceSearchInput = debounce((value) => {
    const queryString = [];

    if (search) {
      const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });

      if (parsedSearch.company) {
        queryString.push(`company=${parsedSearch.company}`);
      }

      if (parsedSearch.pageNumber) {
        queryString.push(`pageNumber=${parsedSearch.pageNumber}`);
      }
    }

    queryString.push(`searchTerm=${value}`);

    history.push(`/products/?${queryString.join("&")}`);
  }, 250);

  return (
    <React.Fragment>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <IconButton
            aria-label="Open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Container>
            <Grid container justify={"space-between"}>
              <Grid item lg={3}>
                <Hidden xsDown implementation="css">
                  <NavbarItems />
                </Hidden>
              </Grid>
              <Grid item lg={6}>
                {hoursMessage && (
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyContent="center"
                    style={{ height: "100%" }}
                  >
                    {hoursMessage}
                    <OpenIndicator open={isOpen}>
                      <ClockIcon />
                      {isOpen ? "Open" : closedMessage}
                    </OpenIndicator>
                  </Box>
                )}
              </Grid>
              <Grid item lg={3}>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="flex-end"
                  style={{ height: "100%" }}
                >
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Search"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      onChange={({ target }) =>
                        debounceSearchInput(target.value)
                      }
                      onKeyPress={(e) => {
                        if (e.key === "Enter")
                          debounceSearchInput(e.target.value);
                      }}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>

      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            open={isOpenOnMobile}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>

            <NavbarDrawerItems onItemTap={handleDrawerToggle} />
          </Drawer>
        </Hidden>
      </nav>
    </React.Fragment>
  );
}

export default withRouter(Navbar);
